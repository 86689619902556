import React, { forwardRef, memo, useCallback, useMemo, useRef } from 'react';
import { DateInputWithPickerProps } from './types';
import {
  Box,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useBreakpointValue,
  useControllableState,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs, { Dayjs } from 'dayjs';
import PaylerDatepicker, {
  PaylerDatepickerWithoutRoot,
} from '../PaylerDatepicker';
import DateInput from './DateInput';
import { WeekDays } from '../WeekDays';
import { useTranslation } from 'react-i18next';
import { emotionColors } from '@payler/ui-theme';
import { BackArrowIcon } from '@payler/ui-icons';
import { RecoilRoot } from 'recoil';
import { MonthsHeader } from '../MonthsHeader';
import { DaysHeader } from '../DaysHeader';
import { usePickerState } from '../state';
import { YearsHeader } from '../YearsHeader';
import { getDayjsOrNull } from '@payler/utils';
import { PaylerDatepickerProps } from '../PaylerDatepickerProps';

/**
 * Поле ввода даты с маской и пикером
 */
export const DateInputWithPicker = forwardRef<
  HTMLInputElement,
  DateInputWithPickerProps
>((props, ref) => {
  const ref2 = useRef<HTMLDivElement>(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [value, setValue] = useControllableState<string>({
    value: props.value,
    onChange: props.onChange,
    defaultValue: '',
  });

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const { showPicker, onPickerValueChanged, ...commonProps } = props;

  const { dateFormat = 'DD-MM-YYYY' } = props;

  const pickerProps = useMemo(() => {
    const pickerValue = getDayjsOrNull(value, dateFormat);
    return {
      value: pickerValue,
      activeMonth: pickerValue ?? dayjs(),
    };
  }, [dateFormat, value]);

  const handlePickerOnChange = useCallback(
    (v: Dayjs | undefined) => {
      onClose();
      const newValue = v?.format(dateFormat) ?? '';
      setValue(newValue);
      onPickerValueChanged?.();
    },
    [dateFormat, onClose, onPickerValueChanged, setValue]
  );

  if (isMobile) {
    return (
      <>
        <DateInput
          isMobile
          {...commonProps}
          onCalendarButtonClick={onToggle}
          onClear={props.onClear}
          ref={ref}
        />
        <MobileDatepickerModal
          close={onClose}
          open={isOpen}
          minDate={props.minDate}
          maxDate={props.maxDate}
          onChange={handlePickerOnChange}
          pickerProps={pickerProps}
          checkDateDisabledFunc={props.checkDateDisabledFunc}
        />
      </>
    );
  }

  return (
    <>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom-start"
        isLazy
      >
        <PopoverTrigger>
          <DateInput
            {...commonProps}
            onCalendarButtonClick={onToggle}
            ref={ref}
          />
        </PopoverTrigger>

        <Portal containerRef={ref2}>
          <PopoverContent maxW="fit-content" border="none" padding="none">
            <PaylerDatepicker
              mode="single"
              minDate={props.minDate}
              maxDate={props.maxDate}
              onChange={handlePickerOnChange}
              checkDateDisabledFunc={props.checkDateDisabledFunc}
              {...pickerProps}
            />
          </PopoverContent>
        </Portal>
      </Popover>
      <Box ref={ref2} zIndex={99999} position="absolute" />
    </>
  );
});

type MobileDatepickerModalProps = {
  close: () => void;
  open: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  onChange: (v: dayjs.Dayjs | undefined) => void;
  checkDateDisabledFunc?: PaylerDatepickerProps['checkDateDisabledFunc'];
  pickerProps: { activeMonth: dayjs.Dayjs; value: null | dayjs.Dayjs };
};

const MobileDatepickerModal = memo((props: MobileDatepickerModalProps) => {
  return (
    <Modal
      isOpen={props.open}
      onClose={props.close}
      size="full"
      motionPreset="slideInRight"
    >
      <ModalOverlay />
      <ModalContent bg="secondary.500">
        <RecoilRoot>
          <Contents {...props} />
        </RecoilRoot>
      </ModalContent>
    </Modal>
  );
});

const Contents = (props: MobileDatepickerModalProps) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [pickerState] = usePickerState();
  const locale = language ?? 'ru';
  return (
    <>
      <Box
        position="sticky"
        top={0}
        zIndex={1}
        bg="secondary.500"
        boxShadow={`0px 0px 4px ${emotionColors.primary25}, 0px 8px 16px ${emotionColors.primary50}`}
      >
        <Box p={2} h={7}>
          <Icon w={3} h={3} as={BackArrowIcon} onClick={props.close} />
        </Box>
        <Box px={2}>
          <Box pb={2}>
            {pickerState === 'days' && <DaysHeader />}
            {pickerState === 'month' && <MonthsHeader />}
            {pickerState === 'years' && <YearsHeader />}
          </Box>
          {pickerState === 'days' && <WeekDays offset={0} locale={locale} />}
        </Box>
      </Box>
      <Box p={2}>
        <PaylerDatepickerWithoutRoot
          showBorder={false}
          showHeader={false}
          mode="single"
          months={[0, -1, -2]}
          layout="vertical"
          showWeekDays={false}
          minDate={props.minDate}
          maxDate={props.maxDate}
          onChange={props.onChange}
          checkDateDisabledFunc={props.checkDateDisabledFunc}
          {...props.pickerProps}
        />
      </Box>
    </>
  );
};
