import { TFasterPaymentsTemplate } from '@payler/api/merchant-cabinet';
import { TFasterPaymentsURLParams } from './types';
import dayjs from 'dayjs';
import { isNil, omitBy } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUIDateFormat } from '../use-ui-dateFormat';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';
import { useApi } from '../../context/ApiContextProvider';
import { TFasterPaymentsFiltersForm } from '../../forms/FasterPaymentsFilters/types';

const templateToFilters = (
  template: TFasterPaymentsTemplate,
  dateFormat: string
): TFasterPaymentsURLParams => {
  const tmp: TFasterPaymentsURLParams = {
    AmountFrom: template.amountFrom
      ? Number(template.amountFrom).toString()
      : undefined,
    AmountTo: template.amountTo
      ? Number(template.amountTo).toString()
      : undefined,
    Statuses: template.statuses ?? undefined,
    CreatedAtFrom: template.dateFrom
      ? dayjs(template.dateFrom).format(dateFormat)
      : undefined,
    CreatedAtTo: template.dateTo
      ? dayjs(template.dateTo).format(dateFormat)
      : undefined,
  };
  return omitBy(tmp, isNil);
};

export const useApplyFasterPaymentsTemplate = () => {
  const [, setParams] = useSearchParams();
  const { PageSize } = usePagerPropsFromUrl();
  const dateFormat = useUIDateFormat();
  return useCallback(
    (template: TFasterPaymentsTemplate) => {
      const params = templateToFilters(template, dateFormat);
      if (PageSize !== 10) {
        params.PageSize = PageSize.toString();
      }
      //Page by default is 0
      setParams(params);
    },
    [PageSize, setParams, dateFormat]
  );
};

export const useDeleteFasterPaymentsTemplateMutation = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  const contractId = useURLContractId();
  return useMutation(
    (id: number) => {
      return api.deleteTemplate(id);
    },
    {
      onSuccess: (data, id) => {
        queryClient.setQueryData(
          ['faster-payments', 'templates', contractId],
          (prev) =>
            (prev as TFasterPaymentsTemplate[]).filter((x) => x.id !== id)
        );
      },
    }
  );
};

export const useSaveFasterPaymentsTemplate = () => {
  const contractId = useURLContractId();
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation(
    (filters: TFasterPaymentsFiltersForm) => {
      if (!contractId) throw new Error('no contractId');
      return api.saveFasterPaymentsTemplate(filters, contractId);
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData<TFasterPaymentsTemplate[]>(
          ['faster-payments', 'templates', contractId],
          (prev) => (prev ? [...prev, data] : [data])
        );
      },
    }
  );
};
