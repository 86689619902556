export const tableHeaderEn = {
  sessionId: 'Session ID',
  orderId: 'Order ID',
  clientId: 'Client ID',
  paymentType: 'Type',
  paymentMethod: 'Payment method',
  status: 'Status',
  amount: 'Amount',
  currency: 'Currency',
  created: 'Created',
  paid: 'Paid',
  code: 'Country code',
  cardAndOrderId: 'Card number / Order ID',
  period: 'Date range',
  email: 'Email',
  createdAndAmount: 'Created / Amount',
  nameAndAuthor: 'Name / Author',
  download: 'Download',
  delete: 'Remove',
  processingName: 'Processing',
  phoneAndOrderId: 'Phone / Order ID',
  description: 'Description',
  idAndOrderId: 'ID / Order ID',
  publicId: 'Public ID',
  date: 'Date',
  type: 'Type',
  result: 'Result',
};

type TTableTitlesEnTranslation = typeof tableHeaderEn;

export const tableHeaderEs: TTableTitlesEnTranslation = {
  sessionId: 'ID de sesión',
  orderId: 'ID de orden',
  clientId: 'ID de cliente',
  paymentType: 'Tipo de pago',
  paymentMethod: 'Método de pago',
  status: 'Estado',
  amount: 'Monto',
  currency: 'Moneda',
  created: 'Creado',
  paid: 'Pagado',
  code: 'Código de país',
  cardAndOrderId: 'Número de tarjeta / ID de pedido',
  period: 'Rango de fechas',
  email: 'Email',
  createdAndAmount: 'Creado / Monto',
  nameAndAuthor: 'Nombre / Autor',
  download: 'Descargar',
  delete: 'Eliminar',
  processingName: 'Procesamiento',
  phoneAndOrderId: 'Teléfono / ID de orden',
  description: 'Descripción',
  idAndOrderId: 'ID / ID de orden',
  publicId: 'Identificación pública',
  //todo: Эта локализация удалится
  date: 'Date',
  type: 'Type',
  result: 'Result',
};

export const tableHeaderRu: TTableTitlesEnTranslation = {
  sessionId: 'ID сессии',
  orderId: 'ID платежа',
  clientId: 'ID клиента',
  paymentType: 'Тип оплаты',
  paymentMethod: 'Способ оплаты',
  status: 'Статус',
  amount: 'Сумма',
  currency: 'Валюта',
  created: 'Создано',
  paid: 'Оплачено',
  code: 'Код страны',
  cardAndOrderId: 'Номер карты / ID платежа',
  period: 'Период',
  email: 'Email',
  createdAndAmount: 'Создано / Сумма',
  nameAndAuthor: 'Название / Автор',
  download: 'Скачать',
  delete: 'Удалить',
  processingName: 'Процессинг',
  phoneAndOrderId: 'Телефон / ID платежа',
  description: 'Описание',
  idAndOrderId: 'ID / ID платежа',
  publicId: 'Публичный ID',
  date: 'Дата',
  type: 'Тип',
  result: 'Результат',
};
