import { useMemo } from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ru from 'javascript-time-ago/locale/ru';
import es from 'javascript-time-ago/locale/es';
import { useMerchantCabinetConfig } from '../config/ConfigProvider';

TimeAgo.addLocale(ru);
TimeAgo.addLocale(es);
TimeAgo.addDefaultLocale(en);

export const useTimeAgo = () => {
  const { culture } = useMerchantCabinetConfig();
  return useMemo(() => {
    return new TimeAgo(culture);
  }, [culture]);
};
