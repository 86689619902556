import { fieldsEn } from './namespaces/fields';
import { transactionStatusEn } from './namespaces/enums/transactionStatus';
import { paymentIndicatorEn } from './namespaces/enums/paymentIndicator';
import { paymentMethodEn } from './namespaces/enums/paymentMethod';
import { notificationKindEn } from './namespaces/enums/notificationKind';
import { rolesEn } from './namespaces/enums/roles';
import { analyticsEn } from './namespaces/analytics';
import { reportsEn } from './namespaces/reports';
import { reportStatusEn } from './namespaces/enums/reportStatus';
import { paymentsEn } from './namespaces/payments';
import { menuEn } from './namespaces/menu';
import { notificationsEn } from './namespaces/notifications';
import { usersEn } from './namespaces/users';
import { invoicesEn } from './namespaces/invoices';
import { authEn } from './namespaces/auth';
import { fasterPayoutsEn } from './namespaces/faster-payouts';
import { settingsEn } from './namespaces/settings';
import { withdrawalsEn } from './namespaces/withdrawals';
import { commonEn } from './namespaces/common';
import { filtersEn } from './namespaces/filters';
import { titlesEn } from './namespaces/titles';
import { timeEn } from './namespaces/time';
import { fasterPaymentsEn } from './namespaces/faster-payments';
import { apmPaymentsEn } from './namespaces/apm-payments';
import { tableHeaderEn } from './namespaces/table-header';

const translationEn = {
  analytics: analyticsEn,
  auth: authEn,
  common: commonEn,
  fasterPayouts: fasterPayoutsEn,
  fasterPayments: fasterPaymentsEn,
  apmPayments: apmPaymentsEn,
  fields: fieldsEn,
  filters: filtersEn,
  invoices: invoicesEn,
  menu: menuEn,
  notificationKind: notificationKindEn,
  notifications: notificationsEn,
  paymentIndicator: paymentIndicatorEn,
  paymentMethod: paymentMethodEn,
  payments: paymentsEn,
  reports: reportsEn,
  reportStatus: reportStatusEn,
  roles: rolesEn,
  settings: settingsEn,
  transactionStatus: transactionStatusEn,
  users: usersEn,
  withdrawals: withdrawalsEn,
  titles: titlesEn,
  time: timeEn,
  tableHeader: tableHeaderEn,
} as const;

export default translationEn;

export type TMerchantCabinetTranslation = typeof translationEn;
