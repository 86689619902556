import React, { Suspense, useState } from 'react';
import {
  DropdownField,
  FloatingInputField,
  TDropdownOption,
} from '@payler/ui-components';
import { Button, HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useUpdateUserLangMutation, useUserInfo } from '../../hooks/user/users';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import {
  TCurrentUserInfo,
  TInterfaceLanguage,
} from '@payler/api/merchant-cabinet';
import { useToasts } from '../../hooks/use-toasts';

type FormData = {
  lang: TInterfaceLanguage;
};

const options: TDropdownOption[] = [
  {
    value: 'ru-RU',
    label: 'Русский',
  },
  {
    value: 'en-US',
    label: 'English',
  },
  {
    value: 'es-ES',
    label: 'Espanol',
  },
];

const WithUserInfo = () => {
  const userInfo = useUserInfo();
  if (!userInfo) return null;
  return <AccountSettingsFormComp userInfo={userInfo} />;
};

const AccountSettingsFormComp: React.FC<{ userInfo: TCurrentUserInfo }> = ({
  userInfo: {
    user: { email, interfaceLanguage },
  },
}) => {
  const { t } = useTranslation();
  const defaultValues = { email, lang: interfaceLanguage };
  const methods = useForm({ defaultValues });
  const { mutate, isLoading } = useUpdateUserLangMutation();
  const [apiError, setApiError] = useState('');
  const getError = useGetAxiosError();
  const toasts = useToasts();
  const onSubmit = methods.handleSubmit(async ({ lang }: FormData) => {
    setApiError('');
    mutate(lang, {
      onSuccess: () =>
        toasts.success(t('settings:account.toast.account.success')),
      onError: (e) => setApiError(getError(e)),
    });
  });
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <VStack alignItems="stretch" spacing={3}>
          <FloatingInputField
            name="email"
            label={t('settings:account.form.email')}
            isReadOnly={true}
          />
          <DropdownField
            options={options}
            fieldName="lang"
            floating
            label={t('settings:account.form.lang')}
            hideClearButton
          />
          {apiError && <ApiErrorText>{apiError}</ApiErrorText>}
          <HStack spacing={2}>
            <Button
              variant="primary"
              type="submit"
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              {t('save')}
            </Button>
            <Button
              variant="secondary"
              onClick={() => methods.reset(defaultValues)}
              isDisabled={isLoading}
            >
              {t('cancel')}
            </Button>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  );
};

/**
 * Настройки аккаунта
 */
export const AccountSettingsForm = () => {
  return (
    <Suspense fallback={null}>
      <WithUserInfo />
    </Suspense>
  );
};
