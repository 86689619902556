import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { isNil, omitBy } from 'lodash';
import {
  getArrayOrUndefined,
  getFloatOrUndefined,
} from '../../helpers/url-params';
import { FASTER_PAYMENTS_STATUS_OPTIONS } from '../../const';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { TFasterPaymentsURLParams } from './types';
import { TFasterPaymentsFiltersForm } from '../../forms/FasterPaymentsFilters/types';

const formValuesToParams = (
  values: TFasterPaymentsFiltersForm
): TFasterPaymentsURLParams => {
  const tmp: TFasterPaymentsURLParams = {
    Statuses: values.Statuses ?? undefined,
    CreatedAtTo: values.CreatedAtTo ? values.CreatedAtTo : undefined,
    CreatedAtFrom: values.CreatedAtFrom ? values.CreatedAtFrom : undefined,

    AmountFrom: values.AmountFrom?.toString() ?? undefined,
    AmountTo: values.AmountTo?.toString() ?? undefined,
    OrderId: values.OrderId ?? undefined,
  };
  return omitBy(tmp, isNil);
};

const queryToFilters = (params: URLSearchParams) => {
  return {
    Statuses: getArrayOrUndefined(
      params.getAll('Statuses'),
      FASTER_PAYMENTS_STATUS_OPTIONS
    ),
    CreatedAtFrom: params.get('CreatedAtFrom') ?? '',
    CreatedAtTo: params.get('CreatedAtTo') ?? '',
    AmountFrom: getFloatOrUndefined(params.get('AmountFrom')),
    AmountTo: getFloatOrUndefined(params.get('AmountTo')),
    OrderId: params.get('OrderId' ?? ''),
  } as TFasterPaymentsFiltersForm;
};

export const useSetFasterPaymentsUrlSearchParams = () => {
  const [, setParams] = useSearchParams();
  const { PageSize } = usePagerPropsFromUrl();

  return useCallback(
    (values: TFasterPaymentsFiltersForm) => {
      const params = formValuesToParams(values);
      if (PageSize !== 10) {
        params.PageSize = PageSize.toString();
      }
      //Page by default is 0
      setParams(params);
    },
    [PageSize, setParams]
  );
};

export const useFasterPaymentsFiltersFromUrl = () => {
  const [params] = useSearchParams();
  return useMemo(() => queryToFilters(params), [params]);
};
