import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useT } from './common';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@payler/ui-components';
import { TInvoiceTemplate } from '@payler/api/merchant-cabinet';
import { useFormContext } from 'react-hook-form';

import { InvoiceFormData } from './InvoiceFormData';
import createLogger from 'debug';
import { TemplateLabeledValue } from '../../components/TemplateLabeledValue/TemplateLabeledValue';
import { TemplateWrap } from '../../components/TemplateWrap/TemplateWrap';
import { useInvoicesTemplatesQuery } from '../../hooks/invoices/queries';
import {
  useDeleteInvoiceTemplateMutation,
  useTemplatePaymentPageType,
} from '../../hooks/invoices/templates';
import { useCurrencyCallbacks } from '../../hooks/currencies-info';
import { DEFAULT_PAYMENT_PAGE } from '../../const';

const log = createLogger('CreateInvoiceForm:Templates');

export const Templates = () => {
  const { data: templates } = useInvoicesTemplatesQuery();
  if (!templates?.length) return null;
  return (
    <Accordion
      alignSelf="stretch"
      borderBottom="0.5px solid"
      borderBottomColor="primary.100"
      mx={-3}
      px={1}
      mt={-2}
    >
      <Header />
      <AccordionBody p={2}>
        <VStack spacing={1} alignItems="stretch">
          {templates.map((template) => (
            <Template template={template} key={template.id} />
          ))}
        </VStack>
      </AccordionBody>
    </Accordion>
  );
};

const Header = () => {
  const { t } = useT();
  return (
    <AccordionHeader>
      <HStack alignItems="center" spacing={2}>
        <Box flex={1}>
          <Text textStyle={TextStyles.tables} color="primary.500">
            {t('templates')}
          </Text>
        </Box>
      </HStack>
    </AccordionHeader>
  );
};

const LifetimeText = ({ lifetime }: { lifetime: number }) => {
  const hours = Math.floor(lifetime / 60) % 24;
  const minutes = Math.floor(lifetime % 60);
  const days = Math.floor(lifetime / (60 * 24));
  const { t } = useTranslation();
  return <span>{t('invoices:remainingText', { days, hours, minutes })}</span>;
};

export const Template = ({ template }: { template: TInvoiceTemplate }) => {
  const { getCurrencySymbol } = useCurrencyCallbacks();
  const { t } = useT();
  const { t: tg } = useTranslation();
  return (
    <TemplateWrap>
      <TemplateLabeledValue
        label={tg('fields:currency')}
        value={template.currency}
      />
      <TemplateLabeledValue
        label={t('amount', {
          currency: getCurrencySymbol(template.currency),
        })}
        value={template.amount}
      />
      <TemplateLabeledValue
        label={t('lifetime')}
        value={<LifetimeText lifetime={template.lifetime} />}
      />
      <TemplateLabeledValue label={t('description')} value={template.product} />
      <TemplateLabeledValue label={t('email')} value={template.email} />
      <TemplateLabeledValue
        label={t('notify')}
        value={template.shouldNotification ? tg('yes') : tg('no')}
      />
      <TemplateLabeledValue
        label={t('paymentPageTypes')}
        value={
          template.paymentPageType
            ? template.paymentPageType
            : DEFAULT_PAYMENT_PAGE
        }
      />

      <Box h="0.5px" bg="primary.100" />
      <Buttons template={template} />
    </TemplateWrap>
  );
};
const Buttons = ({ template }: { template: TInvoiceTemplate }) => {
  const { reset } = useFormContext<InvoiceFormData>();
  const paymentPageType = useTemplatePaymentPageType(template.paymentPageType);

  const fillForm = () => {
    const values: InvoiceFormData = {
      ...template,
      remember: false,
      product: template.product ?? '',
      email: template.email ?? '',
      paymentPageType,
    };
    log('apply template: %O', values);
    reset(values);
  };
  const deleteMutation = useDeleteInvoiceTemplateMutation();
  const { t } = useTranslation();

  const handleDelete = () => {
    deleteMutation.mutate(template.id);
  };
  return (
    <HStack spacing={2}>
      <Button
        variant="link"
        color="primary.500"
        textStyle={TextStyles.Caption12Medium}
        onClick={fillForm}
        disabled={deleteMutation.isLoading}
      >
        {t('filters:apply')}
      </Button>
      <Button
        variant="link"
        color="red.500"
        textStyle={TextStyles.Caption12Medium}
        onClick={handleDelete}
        disabled={deleteMutation.isLoading}
      >
        {t('filters:delete')}
      </Button>
    </HStack>
  );
};
