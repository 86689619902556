import { chakra, useToast } from '@chakra-ui/react';
import React from 'react';
import SavePaymentsToReportForm from '../forms/SavePaymentsToReportsForm/SavePaymentsToReportForm';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToasts } from '../hooks/use-toasts';
import { PaylerModal } from '@payler/ui-components';

const newSavePaymentsToReportModalOpenedAtom = atom({
  key: createRecoilKey('newSavePaymentsToReportModalOpenedAtom'),
  default: false,
});

export const useShowSavePaymentsToReportModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(newSavePaymentsToReportModalOpenedAtom, true);
      },
    []
  );

export const useCloseSavePaymentsToReportModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(newSavePaymentsToReportModalOpenedAtom, false);
      },
    []
  );

type Props = {
  onSave: (name: string) => Promise<void>;
};

export const SavePaymentsToReportModal = ({ onSave }: Props) => {
  const navigate = useNavigate();
  const open = useRecoilValue(newSavePaymentsToReportModalOpenedAtom);
  const close = useCloseSavePaymentsToReportModal();

  const toast = useToast();
  const toasts = useToasts();

  const { t } = useTranslation();
  const handleLinkClick = () => {
    toast.closeAll();
    navigate('/reports');
  };

  const handleSubmit = () => {
    toasts.success(
      <chakra.a
        onClick={handleLinkClick}
        textDecoration="underline"
        cursor="pointer"
      >
        {t('reports:goToReports')}
      </chakra.a>,
      t('reports:reportCreated')
    );
    close();
  };
  return (
    <PaylerModal close={close} open={open} title={t('reports:newReport')}>
      <SavePaymentsToReportForm onSubmit={handleSubmit} onSave={onSave} />
    </PaylerModal>
  );
};
