import React from 'react';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import CreateNotificationSettingForm from '../forms/notifications/CreateNotificationSettingForm';
import { PaylerModal } from '@payler/ui-components';

const createNotificationSettingModalOpenedAtom = atom({
  key: createRecoilKey('createNotificationSettingModalOpenedAtom'),
  default: false,
});

export const useShowCreateNotificationSettingModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(createNotificationSettingModalOpenedAtom, true);
      },
    []
  );

export const useCloseCreateNotificationSettingModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(createNotificationSettingModalOpenedAtom, false);
      },
    []
  );

export const CreateNotificationSettingModal = () => {
  const open = useRecoilValue(createNotificationSettingModalOpenedAtom);
  const close = useCloseCreateNotificationSettingModal();
  const { t } = useTranslation();
  return (
    <PaylerModal
      close={close}
      open={open}
      title={t('notifications:createTitle')}
    >
      <CreateNotificationSettingForm onCancel={close} onSubmit={close} />
    </PaylerModal>
  );
};
