import { TMerchantCabinetTranslation } from './en';
import { fieldsEs } from './namespaces/fields';
import { transactionStatusEs } from './namespaces/enums/transactionStatus';
import { paymentIndicatorEs } from './namespaces/enums/paymentIndicator';
import { paymentMethodEs } from './namespaces/enums/paymentMethod';
import { notificationsKindEs } from './namespaces/enums/notificationKind';
import { rolesEs } from './namespaces/enums/roles';
import { analyticsEs } from './namespaces/analytics';
import { reportsEs } from './namespaces/reports';
import { reportStatusEs } from './namespaces/enums/reportStatus';
import { paymentsEs } from './namespaces/payments';
import { menuEs } from './namespaces/menu';
import { notificationsEs } from './namespaces/notifications';
import { usersEs } from './namespaces/users';
import { invoicesEs } from './namespaces/invoices';
import { authEs } from './namespaces/auth';
import { fasterPayoutsEs } from './namespaces/faster-payouts';
import { settingsEs } from './namespaces/settings';
import { withdrawalsEs } from './namespaces/withdrawals';
import { commonEs } from './namespaces/common';
import { filtersEs } from './namespaces/filters';
import { titlesEs } from './namespaces/titles';
import { timeEs } from './namespaces/time';
import { fasterPaymentsEs } from './namespaces/faster-payments';
import { apmPaymentsEs } from './namespaces/apm-payments';
import { tableHeaderEs } from './namespaces/table-header';

const translationEs: TMerchantCabinetTranslation = {
  analytics: analyticsEs,
  auth: authEs,
  common: commonEs,
  fasterPayouts: fasterPayoutsEs,
  fasterPayments: fasterPaymentsEs,
  apmPayments: apmPaymentsEs,
  fields: fieldsEs,
  filters: filtersEs,
  invoices: invoicesEs,
  menu: menuEs,
  notificationKind: notificationsKindEs,
  notifications: notificationsEs,
  paymentIndicator: paymentIndicatorEs,
  paymentMethod: paymentMethodEs,
  payments: paymentsEs,
  reports: reportsEs,
  reportStatus: reportStatusEs,
  roles: rolesEs,
  settings: settingsEs,
  transactionStatus: transactionStatusEs,
  users: usersEs,
  withdrawals: withdrawalsEs,
  titles: titlesEs,
  time: timeEs,
  tableHeader: tableHeaderEs,
} as const;

export default translationEs;
