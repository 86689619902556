import { TEditNotificationSettingForm } from './types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Grid, HStack, VStack } from '@chakra-ui/react';
import React, { FC, memo, Suspense, useEffect, useMemo, useState } from 'react';
import { LoadingBox } from '@payler/ui-components';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import {
  TNotificationSetting,
  TUpdateNotificationSetting,
} from '@payler/api/merchant-cabinet';
import { useToasts } from '../../hooks/use-toasts';
import { useUpdateNotificationSettingMutation } from '../../hooks/settings/notifications';
import { Triggers } from './Triggers';
import { Emails } from './Emails';
import { NotificationType } from './NotificationType';
import { NotificationPostUrl } from './NotificationPostUrl';
import { useNotificationResolver } from './useNotificationResolver';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';

type Props = {
  onCancel?: VoidFunction;
  onSubmit?: VoidFunction;
  notification: TNotificationSetting;
};

const Comp: FC<Props> = ({ onCancel, onSubmit, notification }) => {
  const [error, setError] = useState('');
  const { t } = useTranslation('notifications');
  const toasts = useToasts();
  const getError = useGetAxiosError();
  const { mutateAsync, isLoading } = useUpdateNotificationSettingMutation();
  const resolver = useNotificationResolver();
  const defaultValues = useMemo(
    () => notificationToFormData(notification),
    [notification]
  );
  const methods = useForm<TEditNotificationSettingForm>({
    defaultValues,
    resolver,
  });

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      onSubmit?.();
    }
  }, [methods.formState.isSubmitSuccessful, onSubmit]);

  const handleSubmit = methods.handleSubmit(async (values) => {
    setError('');
    await mutateAsync(
      {
        id: notification.id,
        data: prepareRequest(values),
      },
      {
        onSuccess: () => {
          toasts.success(t('updated'));
        },
        onError: (e) => {
          setError(getError(e));
          toasts.error(error);
          throw new Error(error);
        },
      }
    );
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <VStack alignItems="stretch" spacing={2}>
          <Grid
            gridTemplateColumns={{
              base: 'minmax(0, 1fr)',
            }}
            gridGap={2}
          >
            <Triggers />
            <NotificationType />
            <Emails />
            <NotificationPostUrl />
          </Grid>
          {error && <ApiErrorText>{error}</ApiErrorText>}
          <HStack
            pt={3}
            alignSelf={{ base: 'stretch', sm: 'flex-start' }}
            spacing={2}
          >
            <Button
              flex={{ base: 1, sm: undefined }}
              onClick={handleSubmit}
              isLoading={isLoading}
              isDisabled={!methods.formState.isDirty}
            >
              {t('update')}
            </Button>
            <Button
              flex={{ base: 1, sm: undefined }}
              variant="secondary"
              onClick={onCancel}
            >
              {t('cancel')}
            </Button>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  );
};

export const EditNotificationSettingForm = memo((props: Props) => (
  <Suspense fallback={<LoadingBox h="700px" />}>
    <Comp {...props} />
  </Suspense>
));

export default EditNotificationSettingForm;

const prepareRequest = (
  formData: TEditNotificationSettingForm
): TUpdateNotificationSetting => {
  return {
    contractId: formData.contractId,
    notificationType: formData.notificationType,
    triggers: formData.triggers,
    notificationReceivers:
      formData.notificationType === 'Post' ? [formData.url] : formData.emails,
  };
};

const notificationToFormData = (
  notification: TNotificationSetting
): TEditNotificationSettingForm => {
  return {
    contractId: notification.contractId,
    notificationType: notification.notificationType,
    triggers: notification.triggers,
    url:
      notification.notificationType === 'Post'
        ? notification.notificationReceivers?.[0] ?? ''
        : '',
    emails:
      notification.notificationType === 'Email'
        ? notification.notificationReceivers ?? []
        : [],
  };
};
