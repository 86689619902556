import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { TFasterPaymentsFiltersForm } from './types';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  createDateFieldResolver,
  FloatingInputField,
  ThinDivider,
} from '@payler/ui-components';
import { FiltersOptionsArrayAccordion } from '../../components/FiltersOptionsArrayAccordion/FiltersOptionsArrayAccordion';
import { FiltersDateRangeAccordion } from '../../components/FiltersDateRangeAccordion/FiltersDateRangeAccordion';
import { FiltersAmountRangeAccordion } from '../../components/FiltersAmountRangeAccordion/FiltersAmountRangeAccordion';

import createLogger from 'debug';
import { useCloseFasterPayoutsModal } from '../../modals/FasterPayoutsFiltersModal';
import isEqual from 'lodash/isEqual';
import { FASTER_PAYMENTS_STATUS_OPTIONS } from '../../const';
import { isNil, omitBy } from 'lodash';
import { useResetFormAndUrlFilters } from '../../hooks/use-reset-form-and-url-filters';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { useUIDateFormat } from '../../hooks/use-ui-dateFormat';
import {
  useFasterPaymentsFiltersFromUrl,
  useSetFasterPaymentsUrlSearchParams,
} from '../../hooks/faster-payments/url';
import { Templates } from './Templates';
import { useSaveFasterPaymentsTemplate } from '../../hooks/faster-payments/templates';

const log = createLogger('FasterPaymentsFilters');

const defaultValues: TFasterPaymentsFiltersForm = {
  CreatedAtTo: null,
  CreatedAtFrom: null,
  AmountFrom: null,
  AmountTo: null,
  Statuses: [],
};

const useCreateResolver = () => {
  const { t } = useTranslation();
  const dateFormat = useUIDateFormat();
  return useCallback(() => {
    return yupResolver(
      yup.object({
        DateFrom: createDateFieldResolver(t, {
          toFieldName: 'DateTo',
          maxDate: dayjs(),
          dateFormat,
        }),
        DateTo: createDateFieldResolver(t, {
          fromFieldName: 'DateFrom',
          maxDate: dayjs(),
          dateFormat,
        }),
      })
    );
  }, [dateFormat, t]);
};

//region form
export const FasterPaymentsFiltersForm = () => {
  const filters = useFasterPaymentsFiltersFromUrl();
  const closeModal = useCloseFasterPayoutsModal();
  const createResolver = useCreateResolver();
  const methods = useForm<TFasterPaymentsFiltersForm>({
    defaultValues,
    resolver: createResolver(),
  });
  const { t } = useTranslation();
  const setFilters = useSetFasterPaymentsUrlSearchParams();

  const handleSubmit = methods.handleSubmit((values) => {
    log('handleSubmit', values);
    setFilters(values);
    closeModal();
  });

  useEffect(() => {
    methods.reset(filters);
    log('resetting form, filters: %O', filters);
  }, [methods, filters]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <VStack alignItems="stretch" spacing={0} divider={<ThinDivider />}>
          <Header />
          <Templates />
          <Box p={2}>
            <FloatingInputField name="OrderId" label={t('fields:orderId')} />
          </Box>
          <Status />
          <Dates />
          <Amount />
          <Buttons />
        </VStack>
      </form>
    </FormProvider>
  );
};
//endregion

//region fields
const Amount = () => (
  <FiltersAmountRangeAccordion
    formFieldFrom="AmountFrom"
    formFieldTo="AmountTo"
    fromKey="fields:from"
    toKey="fields:to"
    titleKey="fields:amount"
  />
);

const Dates = () => (
  <FiltersDateRangeAccordion
    formFieldFrom="CreatedAtFrom"
    formFieldTo="CreatedAtTo"
    fromKey="fields:from"
    toKey="fields:to"
    titleKey="fields:period"
    dateFormat="DD.MM.YYYY"
  />
);

const Status = () => (
  <FiltersOptionsArrayAccordion
    formFieldName="Statuses"
    fieldNameKey="fields:paymentStatus"
    options={FASTER_PAYMENTS_STATUS_OPTIONS}
    optionsPrefix="fasterPayments:status."
    multi
  />
);

//endregion

//region count selected
const arrayAndNotEmpty = (val: unknown) => Array.isArray(val) && val.length > 0;
const useFiltersCount = () => {
  const { watch } = useFormContext<TFasterPaymentsFiltersForm>();
  const data = watch();
  return useMemo(() => {
    let count = 0;
    count += data.AmountFrom || data.AmountTo ? 1 : 0;
    count += data.CreatedAtFrom || data.CreatedAtTo ? 1 : 0;
    count += arrayAndNotEmpty(data.Statuses) ? 1 : 0;
    return count;
  }, [data]);
};
//endregion

//region header
const Header = () => {
  const { t } = useTranslation();
  const reset = useResetFormAndUrlFilters();
  const count = useFiltersCount();

  return (
    <HStack justifyContent="space-between">
      <Box pl={3} pr={2} py={2} alignSelf="flex-start">
        <Text as="h4" textStyle={TextStyles.h4} color="primary.500">
          {t('filters:title')}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
          {count} {t('filters:selected')}
        </Text>
      </Box>
      <Box pr={3}>
        <Button variant="secondary" onClick={reset}>
          {t('filters:reset')}
        </Button>
      </Box>
    </HStack>
  );
};
//endregion

//region buttons
const isEmpty = (val: unknown) =>
  isNil(val) || (Array.isArray(val) && val.length === 0);

const Buttons = () => {
  const filters = useFasterPaymentsFiltersFromUrl();
  const { t } = useTranslation();
  const count = useFiltersCount();
  const { getValues, watch } = useFormContext<TFasterPaymentsFiltersForm>();
  const save = useSaveFasterPaymentsTemplate();
  const handleSave = () => {
    save.mutate(getValues());
  };

  const applyDisabled = isEqual(
    omitBy(filters, isEmpty),
    omitBy(watch(), isEmpty)
  );

  return (
    <VStack p={2} alignItems="center" spacing="10px">
      <Button type="submit" w="100%" disabled={applyDisabled}>
        {t('filters:submit')}
      </Button>
      <Button
        variant="link"
        disabled={!count || save.isLoading}
        onClick={handleSave}
      >
        {t('filters:save')}
      </Button>
    </VStack>
  );
};
//endregion
