import { Button, Text, VStack } from '@chakra-ui/react';
import { FloatingInputField } from '@payler/ui-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Suspense, useState } from 'react';
import { AxiosError } from 'axios';
import { TTransactionWithHistory } from '@payler/api/merchant-cabinet';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';
import { TextStyles } from '@payler/ui-theme';
import { usePaymentDataQuery } from '../../hooks/payments/queries';
import {
  useRefreshPaymentData,
  useRefundPayment,
} from '../../hooks/payments/payment';
import { useCurrencyCallbacks } from '../../hooks/currencies-info';

type Props = {
  onSubmit?: VoidFunction;
  id: number;
};

type FormData = {
  amount: number;
  password: string;
};

function createResolver(
  payment: TTransactionWithHistory,
  t: TFunction,
  divider: number
) {
  return yupResolver(
    yup.object({
      amount: yup
        .number()
        .typeError(t('payments:amountError'))
        .required(t('payments:amountError'))
        .positive(t('payments:amountError'))
        .max(payment.transaction.rest / divider, t('payments:amountError')),
      password: yup.string().required(t('payments:paymentPasswordRequired')),
    })
  );
}

const RefundPaymentFormComp = ({ onSubmit: outerOnSubmit, id }: Props) => {
  const { getCurrencyUnitRate } = useCurrencyCallbacks();
  const [apiError, setApiError] = useState('');
  const getError = useGetAxiosError();
  const refresh = useRefreshPaymentData();
  const refund = useRefundPayment();
  const { data: payment } = usePaymentDataQuery(id, true);
  if (!payment) throw new Error('no payment');
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();
  const divider = getCurrencyUnitRate(payment.transaction.currencyCode);
  const methods = useForm<FormData>({
    defaultValues: { amount: payment.transaction.rest / divider, password: '' },
    resolver: createResolver(payment, t, divider),
  });

  const onSubmit = methods.handleSubmit(
    async ({ amount, password }: FormData) => {
      try {
        setSubmitting(true);
        try {
          setApiError('');
          await refund(id, amount, password, divider);
          outerOnSubmit?.();
        } catch (e) {
          setApiError(getError(e));
          if (e instanceof AxiosError) {
            if (e.response?.data?.errors) {
              refresh(id);
            }
          }
        }
      } finally {
        setSubmitting(false);
      }
    }
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <VStack alignItems="stretch" spacing={3}>
          <FloatingInputField
            name="amount"
            label={t('payments:amount')}
            autoFocus
            isDisabled={!payment.transaction.canPartRetrieve}
            autoComplete="off"
          />
          <FloatingInputField
            name="password"
            label={t('payments:paymentPassword')}
            isDisabled={!payment.transaction.canPartRetrieve}
            autoComplete="off"
            type="password"
          />
          {apiError && <ApiErrorText>{apiError}</ApiErrorText>}

          <Button type="submit" isLoading={submitting}>
            {t('payments:refund')}
          </Button>
          <Text
            my={2}
            textStyle={TextStyles.Caption12Medium}
            color="primary.350"
            textAlign="center"
          >
            {t('payments:support')}
          </Text>
        </VStack>
      </form>
    </FormProvider>
  );
};

/**
 * Возврат
 */
export const RefundPaymentForm = ({ onSubmit, id }: Props) => {
  return (
    <Suspense fallback={null}>
      <RefundPaymentFormComp onSubmit={onSubmit} id={id} />
    </Suspense>
  );
};

export default RefundPaymentForm;
