import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { isNil, omitBy } from 'lodash';
import { TFasterPayoutCurrency } from '@payler/api/merchant-cabinet';
import {
  getArrayOrUndefined,
  getFloatOrUndefined,
} from '../../helpers/url-params';
import { FPS_CURRENCY_OPTIONS, FPS_FILTERS_STATUS_OPTIONS } from '../../const';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { TFasterPayoutsURLParams } from './types';
import { TFasterPayoutsFiltersForm } from '../../forms/FasterPayoutsFilters/types';

const formValuesToParams = (
  values: TFasterPayoutsFiltersForm
): TFasterPayoutsURLParams => {
  const tmp: TFasterPayoutsURLParams = {
    Statuses: values.Statuses ?? undefined,
    Currency: values.Currency ?? undefined,
    DateFrom: values.DateFrom ? values.DateFrom : undefined,
    DateTo: values.DateTo ? values.DateTo : undefined,
    AmountFrom: values.AmountFrom?.toString() ?? undefined,
    AmountTo: values.AmountTo?.toString() ?? undefined,
    SearchQuery: values.SearchQuery ?? undefined,
  };
  return omitBy(tmp, isNil);
};

const queryToFilters = (params: URLSearchParams) => {
  const currency = params.get('Currency') as TFasterPayoutCurrency;
  return {
    Statuses: getArrayOrUndefined(
      params.getAll('Statuses'),
      FPS_FILTERS_STATUS_OPTIONS
    ),
    Currency:
      currency && FPS_CURRENCY_OPTIONS.includes(currency)
        ? params.get('Currency')
        : undefined,
    DateFrom: params.get('DateFrom') ?? '',
    DateTo: params.get('DateTo') ?? '',
    AmountFrom: getFloatOrUndefined(params.get('AmountFrom')),
    AmountTo: getFloatOrUndefined(params.get('AmountTo')),
    SearchQuery: params.get('SearchQuery'),
  } as TFasterPayoutsFiltersForm;
};

export const useSetFasterPayoutsUrlSearchParams = () => {
  const [, setParams] = useSearchParams();
  const { PageSize } = usePagerPropsFromUrl();

  return useCallback(
    (values: TFasterPayoutsFiltersForm) => {
      const params = formValuesToParams(values);
      if (PageSize !== 10) {
        params.PageSize = PageSize.toString();
      }
      //Page by default is 0
      setParams(params);
    },
    [PageSize, setParams]
  );
};

export const useFasterPayoutsFiltersFromUrl = () => {
  const [params] = useSearchParams();
  return useMemo(() => queryToFilters(params), [params]);
};
