import dayjs, { Dayjs } from 'dayjs';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { getDayjsOrNull } from '@payler/utils';

/**
 * Возвращает смещение в месяцах для PaylerDatePicker
 * @param date
 * @param currentDate
 */
export const getPaylerDatepickerMonthOffset = (
  date: Dayjs,
  currentDate = dayjs()
): number => {
  const y1 = date.year();
  const y2 = currentDate.year();
  const m1 = date.month();
  const m2 = currentDate.month();
  return (y1 - y2) * 12 + m1 - m2;
};

export type TCreateDateResolverConfig = {
  /**
   * Формат даты
   */
  dateFormat: string;
  /**
   * Минимальное значение даты
   */
  minDate?: Dayjs;
  /**
   * Максимальное значение даты
   */
  maxDate?: Dayjs;
  /**
   * Поле может быть пустым?
   */
  canBeEmpty?: boolean;
  /**
   * название поля с датой начала периода (указать для поля окончания)
   */
  fromFieldName?: string;
  /**
   * название поля с датой окончания периода (указать для поля начала)
   */
  toFieldName?: string;
};

const getMinDate = (
  minDate: Dayjs | null | undefined,
  otherFieldValue: Dayjs | null | undefined
) => {
  if (minDate && otherFieldValue) {
    return minDate.isBefore(otherFieldValue, 'date')
      ? otherFieldValue
      : minDate;
  }
  if (otherFieldValue) return otherFieldValue;
  if (minDate) return minDate;
  return undefined;
};

const getMaxDate = (
  maxDate: Dayjs | null | undefined,
  otherFieldValue: Dayjs | null | undefined
) => {
  if (maxDate && otherFieldValue) {
    return maxDate.isAfter(otherFieldValue, 'date') ? otherFieldValue : maxDate;
  }
  if (otherFieldValue) return otherFieldValue;
  if (maxDate) return maxDate;
  return undefined;
};

/**
 * Создать валидатор для поля с датой
 * @param t
 * @param config
 */
export const createDateFieldResolver = (
  t: TFunction,
  config: TCreateDateResolverConfig
) => {
  const { canBeEmpty = true } = config;
  return yup.string().test((value, ctx) => {
    if (canBeEmpty && !value) {
      return true;
    }
    if (canBeEmpty && value?.trim() === '') {
      return true;
    }
    const date = getDayjsOrNull(value, config.dateFormat);
    const refStartDate = config.fromFieldName
      ? getDayjsOrNull(ctx.parent[config.fromFieldName], config.dateFormat)
      : undefined;
    const refEndDate = config.toFieldName
      ? getDayjsOrNull(ctx.parent[config.toFieldName], config.dateFormat)
      : undefined;

    const minDate = getMinDate(config.minDate, refStartDate);
    const maxDate = getMaxDate(config.maxDate, refEndDate);

    if (date === null) {
      return ctx.createError({
        message: t('datePicker.badValue', 'Please enter a valid date'),
      });
    }
    if (minDate && date.isBefore(minDate, 'date')) {
      return ctx.createError({
        message: t('datePicker.minError', 'Date must be after {{minDate}}', {
          minDate: minDate.format(config.dateFormat),
        }),
      });
    }
    if (maxDate && date.isAfter(maxDate, 'date')) {
      return ctx.createError({
        message: t('datePicker.maxError', 'Date must be before {{maxDate}}', {
          maxDate: maxDate.format(config.dateFormat),
        }),
      });
    }
    return true;
  });
};

/**
 * Преобразовать dateFormat dayjs в маску react-input-mask
 * @param df
 */
export const dateFormatToMask = (df: string): string => {
  return df.replace(/[DYM]/g, '9');
};

export const useCreateDateResolverExample = () => {
  const { t } = useTranslation();
  return useCallback(
    (config: TCreateDateResolverConfig) => {
      return yupResolver(
        yup.object({
          date: createDateFieldResolver(t, config),
        })
      );
    },
    [t]
  );
};
