import { Button, HStack, VStack } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import {
  atom,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { createRecoilKey } from '../helpers/createKey';
import { useToasts } from '../hooks/use-toasts';
import { NotificationWithIcon, PaylerModal } from '@payler/ui-components';
import { DangerBigIcon } from '@payler/ui-icons';
import { useDeleteNotificationSettingMutation } from '../hooks/settings/notifications';
import { useTranslation } from 'react-i18next';
import { useGetAxiosError } from '../hooks/use-get-axios-error';

const deleteNotificationSettingModalIdAtom = atom<number | null>({
  key: createRecoilKey('deleteNotificationSettingModalIdAtom'),
  default: null,
});

const useCloseDeleteNotificationSettingModal = () =>
  useRecoilCallback(({ set }) => () => {
    set(deleteNotificationSettingModalIdAtom, null);
  });

export const useShowDeleteNotificationSettingModal = () =>
  useSetRecoilState(deleteNotificationSettingModalIdAtom);

export const DeleteNotificationSettingModal = () => {
  const { t } = useTranslation('notifications');
  const id = useRecoilValue(deleteNotificationSettingModalIdAtom);
  const isOpen = !!id;
  const close = useCloseDeleteNotificationSettingModal();
  return (
    <PaylerModal close={close} open={isOpen}>
      <VStack spacing={4} w="100%">
        <NotificationWithIcon
          title={t('deleteTitle')}
          text={t('deleteDescription')}
          icon={DangerBigIcon}
        />
      </VStack>
      <Buttons />
    </PaylerModal>
  );
};

const Buttons = () => {
  const id = useRecoilValue(deleteNotificationSettingModalIdAtom);
  const { t } = useTranslation('notifications');
  const toasts = useToasts();
  const getError = useGetAxiosError();
  const close = useCloseDeleteNotificationSettingModal();
  const { mutateAsync, isLoading } = useDeleteNotificationSettingMutation();
  const handleDelete = useCallback(async () => {
    if (!id) return;
    await mutateAsync(id, {
      onSuccess: () => {
        close();
        toasts.success(t('notifications:deleted'));
      },
      onError: (e) => {
        toasts.error(getError(e));
      },
    });
  }, [id, close, mutateAsync, toasts, t, getError]);
  return (
    <HStack spacing={2}>
      <Button flex={1} variant="secondary" onClick={close} disabled={isLoading}>
        {t('cancel')}
      </Button>
      <Button
        flex={1}
        variant="red"
        onClick={handleDelete}
        isLoading={isLoading}
      >
        {t('delete')}
      </Button>
    </HStack>
  );
};
