import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { TAntifraudFiltersForm, TPaymentFiltersForm } from './types';
import { AntifraudTemplates } from './Templates';
import React, { useCallback, useEffect, useMemo } from 'react';
import createLogger from 'debug';
import {
  createDateFieldResolver,
  FloatingInputField,
  ThinDivider,
} from '@payler/ui-components';
import { useURLContractCurrencies } from '../../hooks/use-url-contract-currencies';
import { FiltersOptionsArrayAccordion } from '../../components/FiltersOptionsArrayAccordion/FiltersOptionsArrayAccordion';
import { FiltersDateRangeAccordion } from '../../components/FiltersDateRangeAccordion/FiltersDateRangeAccordion';
import {
  ALL_PAYMENT_INDICATORS,
  PAYMENT_FILTERS_DEBIT_STATUSES,
  PAYMENT_FILTERS_PAYMENT_METHODS,
} from '../../const';
import { FiltersAmountRangeAccordion } from '../../components/FiltersAmountRangeAccordion/FiltersAmountRangeAccordion';
import {
  useAntifraudFiltersFromUrl,
  useSetAntifraudUrlSearchParams,
} from '../../hooks/antifraud/url';
import { useCloseAntifraudPaymentsFiltersModal } from '../../modals/AntifraudPaymentsFiltersModal';
import { useSaveAntifraudTemplate } from '../../hooks/antifraud/templates';
import isEqual from 'lodash/isEqual';
import { isNil, omitBy } from 'lodash';
import { useResetFormAndUrlFilters } from '../../hooks/use-reset-form-and-url-filters';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { useUIDateFormat } from '../../hooks/use-ui-dateFormat';

const log = createLogger('AntifraudPaymentsFiltersForm');

const useCreateResolver = () => {
  const { t } = useTranslation();
  const dateFormat = useUIDateFormat();
  return useCallback(() => {
    return yupResolver(
      yup.object({
        StartDate: createDateFieldResolver(t, {
          toFieldName: 'EndDate',
          maxDate: dayjs(),
          dateFormat,
          canBeEmpty: true,
        }),
        EndDate: createDateFieldResolver(t, {
          fromFieldName: 'StartDate',
          maxDate: dayjs(),
          dateFormat,
          canBeEmpty: true,
        }),
      })
    );
  }, [dateFormat, t]);
};

export const AntifraudPaymentsFiltersForm = () => {
  const closeModal = useCloseAntifraudPaymentsFiltersModal();
  const setFilters = useSetAntifraudUrlSearchParams();
  const paymentFilters = useAntifraudFiltersFromUrl();
  const createResolver = useCreateResolver();
  const { t } = useTranslation();
  const methods = useForm<TAntifraudFiltersForm>({
    defaultValues: paymentFilters,
    resolver: createResolver(),
  });
  const { reset } = methods;
  useEffect(() => {
    log('updating filters from recoil state: %O', paymentFilters);
    reset(paymentFilters);
  }, [reset, paymentFilters]);

  const handleSubmit = methods.handleSubmit((values) => {
    log('applying filters: %O', values);
    setFilters(values);
    closeModal();
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <VStack alignItems="stretch" spacing={0} divider={<ThinDivider />}>
          <Header />
          <React.Suspense fallback={null}>
            <AntifraudTemplates />
          </React.Suspense>
          <Box p={2}>
            <FloatingInputField
              name="SearchQuery"
              label={t('fields:transactionNumber')}
            />
          </Box>
          <DateRange />
          <TransactionStatus />
          <PaymentIndicators />
          <PaymentMethods />
          <React.Suspense fallback={null}>
            <Currencies />
          </React.Suspense>
          <Amount />
          <Buttons />
        </VStack>
      </form>
    </FormProvider>
  );
};

const arrayAndNotEmpty = (val: unknown) => Array.isArray(val) && val.length > 0;

const useFiltersCount = () => {
  const { watch } = useFormContext<TPaymentFiltersForm>();
  const data = watch();
  return useMemo(() => {
    let count = 0;
    count += data.StartAmount || data.EndAmount ? 1 : 0;
    count += data.StartDate || data.EndDate ? 1 : 0;
    count += arrayAndNotEmpty(data.PaymentMethods) ? 1 : 0;
    count += arrayAndNotEmpty(data.PaymentIndicators) ? 1 : 0;
    count += arrayAndNotEmpty(data.TransactionStatuses) ? 1 : 0;
    count += arrayAndNotEmpty(data.OperationStatuses) ? 1 : 0;
    count += arrayAndNotEmpty(data.CurrencyCodes) ? 1 : 0;
    return count;
  }, [data]);
};

const Header = () => {
  const { t } = useTranslation();
  const reset = useResetFormAndUrlFilters();
  const count = useFiltersCount();

  return (
    <HStack justifyContent="space-between">
      <Box pl={3} pr={2} py={2} alignSelf="flex-start">
        <Text as="h4" textStyle={TextStyles.h4} color="primary.500">
          {t('filters:title')}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
          {count} {t('filters:selected')}
        </Text>
      </Box>
      <Box pr={3}>
        <Button variant="secondary" onClick={reset}>
          {t('filters:reset')}
        </Button>
      </Box>
    </HStack>
  );
};

const isEmpty = (val: unknown) =>
  isNil(val) || (Array.isArray(val) && val.length === 0);

const Buttons = () => {
  const filters = useAntifraudFiltersFromUrl();
  const count = useFiltersCount();
  const { t } = useTranslation();
  const { getValues, watch } = useFormContext<TAntifraudFiltersForm>();
  const saveMutation = useSaveAntifraudTemplate();
  const handleSave = () => {
    saveMutation.mutate(getValues());
  };

  const applyDisabled = isEqual(
    omitBy(filters, isEmpty),
    omitBy(watch(), isEmpty)
  );

  return (
    <VStack p={2} alignItems="center" spacing="10px">
      <Button type="submit" w="100%" disabled={applyDisabled}>
        {t('filters:submit')}
      </Button>
      <Button
        variant="link"
        disabled={!count || saveMutation.status === 'loading'}
        onClick={handleSave}
      >
        {t('filters:save')}
      </Button>
    </VStack>
  );
};

export default AntifraudPaymentsFiltersForm;

//region fields
export const Currencies = () => {
  const currencies = useURLContractCurrencies();
  return (
    <FiltersOptionsArrayAccordion
      fieldNameKey="fields:currency"
      formFieldName="CurrencyCodes"
      options={currencies}
      optionsPrefix="currency:"
      multi
    />
  );
};

export const DateRange = () => {
  const dateFormat = useUIDateFormat();
  return (
    <FiltersDateRangeAccordion
      formFieldFrom="StartDate"
      formFieldTo="EndDate"
      fromKey="fields:from"
      titleKey="fields:period"
      toKey="fields:to"
      dateFormat={dateFormat}
    />
  );
};

export const PaymentIndicators = () => {
  return (
    <FiltersOptionsArrayAccordion
      fieldNameKey="fields:paymentIndicator"
      formFieldName="PaymentIndicators"
      options={ALL_PAYMENT_INDICATORS}
      optionsPrefix="paymentIndicator:"
      multi
    />
  );
};

export const PaymentMethods = () => {
  return (
    <FiltersOptionsArrayAccordion
      fieldNameKey="fields:paymentMethod"
      formFieldName="PaymentMethods"
      options={PAYMENT_FILTERS_PAYMENT_METHODS}
      optionsPrefix="paymentMethod:"
      multi
    />
  );
};

export const Amount = () => (
  <FiltersAmountRangeAccordion
    formFieldFrom="StartAmount"
    formFieldTo="EndAmount"
    fromKey="fields:from"
    toKey="fields:to"
    titleKey="fields:amount"
  />
);

export const TransactionStatus = () => {
  return (
    <FiltersOptionsArrayAccordion
      fieldNameKey="fields:paymentStatus"
      formFieldName="TransactionStatuses"
      options={PAYMENT_FILTERS_DEBIT_STATUSES}
      optionsPrefix="transactionStatus:"
      multi
    />
  );
};
//endregion
