import { MayBe, TTransaction, TUserParams } from '@payler/api/merchant-cabinet';
import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  ScrollBarWithFade,
} from '@payler/ui-components';
import React from 'react';
import {
  Badge,
  ComponentStyleConfig,
  HStack,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useBreakpointValue,
  useClipboard,
} from '@chakra-ui/react';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { getTransactionStatusBadgeVariant } from '../../tables/PaymentsTable/getTransactionStatusBadgeVariant';
import { CardTypeIcon } from '../../tables/PaymentsTable/CardTypeIcon';
import {
  CopyIcon,
  PaymentApplePay24Icon,
  PaymentGooglePay24Icon,
  PaymentLinkPayment24Icon,
  PaymentRecurrent24Icon,
} from '@payler/ui-icons';
import { LabeledValue } from '../LabeledValue/LabeledValue';
import { ReactComponent as YandexPayIcon } from './yandex-pay.method.svg';
import { getAdditionalParams } from './helper';

type Props = { transaction: TTransaction };

const tableStyleConfig: ComponentStyleConfig = {
  baseStyle: {
    table: {
      minW: { base: '300px', xl: '800px' },
      maxW: { base: '100%', sm: 'unset' },
      width: '100%',
    },
    tr: {
      height: { base: '54px', sm: 9 },
      borderBottom: `0.5px solid ${emotionColors.primary100}`,
    },
    td: {
      textStyle: TextStyles.tables,
      px: 1,
      '&:first-of-type': {
        pl: { base: 2, sm: 3 },
        minW: '128px',
        w: '20%',
      },
      '&:last-of-type': {
        pr: { base: 2, sm: 3 },
      },
    },
  },
};

export const PaymentTransaction = ({ transaction }: Props) => {
  const { t } = useTranslation('payments');
  const isMobile = useBreakpointValue({ base: true, xl: false });
  return (
    <Card
      borderLeftWidth={{ base: 0, sm: 0.5 }}
      borderRightWidth={{ base: 0, sm: 0.5 }}
    >
      <CardHead>
        <CardTitle>{t('transactionTitle')}</CardTitle>
        <Images transaction={transaction} />
      </CardHead>
      <CardContent p={0}>
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Tbody>
                <Tr>
                  <Td>{t('status')}</Td>
                  <Td>
                    <Badge
                      variant={getTransactionStatusBadgeVariant(
                        transaction.transactionStatus
                      )}
                    >
                      {transaction.statusDescription}
                    </Badge>
                  </Td>
                </Tr>
                {!isMobile && <DesktopRequisites transaction={transaction} />}
                {isMobile && <MobileRequisites transaction={transaction} />}
                <Tr>
                  <Td>{t('transactionType')}</Td>
                  <Td>{transaction.transactionType}</Td>
                </Tr>

                <Tr>
                  <Td>{t('orderId')}</Td>
                  <Td>{transaction.merchantOrderId}</Td>
                </Tr>
                <Tr>
                  <Td>{t('ip')}</Td>
                  <Td>{transaction.ip}</Td>
                </Tr>
                <Tr>
                  <Td>{t('email')}</Td>
                  <Td>{transaction.userEmail}</Td>
                </Tr>
                <Tr>
                  <Td>{t('description')}</Td>
                  <Td>{transaction.product}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
        <AdditionalParams
          title={t('payPageParams')}
          value={transaction.payPageParams}
        />
        <AdditionalParams title={t('userData')} value={transaction.userData} />
        <AdditionalParams
          title={t('userEnteredParams')}
          value={transaction.userEnteredParams}
        />
      </CardContent>
    </Card>
  );
};

const AdditionalParams = ({
  title,
  value,
}: {
  title: string;
  value: MayBe<TUserParams>;
}) => {
  let text = getAdditionalParams(value);
  const { t } = useTranslation();
  const { hasCopied, onCopy } = useClipboard(text);
  if (!text) return null;

  return (
    <Card bg="primary.25" m={2}>
      <CardHead>
        <CardTitle textStyle={TextStyles.Subtitle14Regular} color="primary.350">
          {title}
        </CardTitle>
        <Tooltip
          shouldWrapChildren
          isOpen={hasCopied || undefined}
          label={hasCopied ? t('copied') : t('clickToCopy')}
          hasArrow
          placement="top"
        >
          <Icon
            as={CopyIcon}
            w="20px"
            h="20px"
            color="primary.300"
            _hover={{ color: 'primary.350' }}
            onClick={onCopy}
          />
        </Tooltip>
      </CardHead>
      <CardContent textStyle={TextStyles.code} color="primary.350">
        {text}
      </CardContent>
    </Card>
  );
};

const Images = ({ transaction }: Props) => {
  const { t } = useTranslation('payments');
  //TODO: добавить СБП (ждём бэк)
  return (
    <HStack spacing={2} color="brands.500">
      {transaction.isInvoicePay && (
        <Tooltip label={t('linkPayment')} shouldWrapChildren>
          <Icon as={PaymentLinkPayment24Icon} w={3} h={3} color="brands.500" />
        </Tooltip>
      )}
      {transaction.isRecurrentPay && (
        <Tooltip label={t('recurringPayment')} shouldWrapChildren>
          <Icon as={PaymentRecurrent24Icon} w={3} h={3} color="brands.500" />
        </Tooltip>
      )}
      {transaction.paymentMethodKey === 'ApplePay' && (
        <Tooltip label="Apple Pay" shouldWrapChildren>
          <Icon as={PaymentApplePay24Icon} w={3} h={3} color="brands.500" />
        </Tooltip>
      )}
      {transaction.paymentMethodKey === 'GooglePay' && (
        <Tooltip label="Google Pay" shouldWrapChildren>
          <Icon as={PaymentGooglePay24Icon} w={3} h={3} color="brands.500" />
        </Tooltip>
      )}
      {transaction.paymentMethodKey === 'YandexPay' && (
        <Tooltip label="Yandex Pay" shouldWrapChildren>
          <Icon as={YandexPayIcon} w={3} h={3} color="brands.500" />
        </Tooltip>
      )}
    </HStack>
  );
};

const DesktopRequisites = ({ transaction }: Props) => {
  const { t } = useTranslation('payments');
  return (
    <Tr>
      <Td>{t('requisites')}</Td>
      <Td>
        <HStack spacing={5}>
          <LabeledValue label={t('country')}>
            {transaction.bankCountryCode}
          </LabeledValue>
          <LabeledValue label={t('bankName')}>
            {transaction.bankName}
          </LabeledValue>
          <LabeledValue label={t('card')}>
            <HStack>
              <Text textStyle={TextStyles.tables}>
                {transaction.panMask?.replace(/x/g, '·')}{' '}
              </Text>
              <CardTypeIcon type={transaction.cardTypeName} />
            </HStack>
          </LabeledValue>
          <LabeledValue label={t('cardHolder')}>
            {transaction.cardHolder}
          </LabeledValue>
        </HStack>
      </Td>
    </Tr>
  );
};

const MobileRequisites = ({ transaction }: Props) => {
  const { t } = useTranslation('payments');
  return (
    <>
      <Tr>
        <Td>{t('country')}</Td>
        <Td>{transaction.bankCountryCode}</Td>
      </Tr>

      <Tr>
        <Td>{t('bankName')}</Td>
        <Td>{transaction.bankName}</Td>
      </Tr>
      <Tr>
        <Td>{t('card')}</Td>
        <Td>
          <HStack>
            <Text textStyle={TextStyles.tables}>
              {transaction.panMask?.replace(/x/g, '·')}{' '}
            </Text>
            <CardTypeIcon type={transaction.cardTypeName} />
          </HStack>
        </Td>
      </Tr>
      <Tr>
        <Td>{t('cardHolder')}</Td>
        <Td>{transaction.cardHolder}</Td>
      </Tr>
    </>
  );
};

export default PaymentTransaction;
