import { Flex, HStack, Icon } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { DownloadIcon } from '@payler/ui-icons';
import React from 'react';
import { useApi } from '../../context/ApiContextProvider';

type TDownloadReportButtonProps = {
  id: number;
  reportName?: string;
  type: 'xls' | 'csv';
};

const DownloadReportButton = ({
  id,
  type,
  reportName,
}: TDownloadReportButtonProps) => {
  const api = useApi();

  const handleDownloadButtonClick = async () => {
    await api.downloadReport(id, type, reportName);
  };

  return (
    <Flex
      as="button"
      type="button"
      role="button"
      cursor="pointer"
      w={7}
      h="30px"
      bg="brands.100"
      py={0.5}
      px={1}
      color="brands.500"
      border="0.5px solid"
      borderColor="brands.100"
      alignItems="center"
      textStyle={TextStyles.Buttons12Small}
      textTransform="uppercase"
      onClick={handleDownloadButtonClick}
    >
      {type}
      <Icon as={DownloadIcon} ml="6px" my={'auto'} />
    </Flex>
  );
};

export const DownloadReportButtons = (
  props: Pick<TDownloadReportButtonProps, 'id' | 'reportName'>
) => {
  return (
    <HStack spacing={2}>
      <DownloadReportButton {...props} type="xls" />
      <DownloadReportButton {...props} type="csv" />
    </HStack>
  );
};
