import { useToast } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

const useSuccessToast = () => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 5000,
    status: 'success',
  });
  return useCallback(
    (description?: React.ReactNode, title?: React.ReactNode) => {
      toast({ title, description });
    },
    [toast]
  );
};

const useErrorToast = () => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 5000,
    status: 'error',
  });
  return useCallback(
    (description?: React.ReactNode, title?: React.ReactNode) => {
      toast({ title, description });
    },
    [toast]
  );
};

export const useToasts = () => {
  const success = useSuccessToast();
  const error = useErrorToast();
  return useMemo(() => ({ success, error }), [success, error]);
};
