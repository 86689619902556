import { css, keyframes } from '@emotion/react';
import { emotionColors, emotionTextStyles } from '@payler/ui-theme';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

const asideLinkMobileStyles = css`
  background: ${emotionColors.primary25};
  box-shadow: inset 0 0 0 1px ${emotionColors.primary50};

  svg {
    color: ${emotionColors.brands500};
  }

  &.active {
    background: ${emotionColors.brands100};
    box-shadow: inset 0 0 0 1px ${emotionColors.brands100};
    color: ${emotionColors.brands500};

    svg {
      color: ${emotionColors.brands500};
    }
  }
`;

const showMenuItems = keyframes`
  0% {
    width: 1px;
    opacity: 0;
  }
  100% {
    width: auto;
    opacity: 1;
  }
`;

interface NavigationSidebarLinks {
  url: string;
  title: string;
  icon: JSX.Element;
}

const asideLinkStyles = css`
  ${emotionTextStyles.subtitle14medium};
  display: flex;
  color: ${emotionColors.primary400};
  background-color: transparent;
  border-radius: 8px;
  will-change: padding;
  outline: none;
  height: 48px;

  svg {
    width: 18px;
    height: 20px;
    fill: ${emotionColors.primary300};
    color: ${emotionColors.primary300};
  }

  &:hover,
  &:focus {
    color: ${emotionColors.brands500};

    svg {
      fill: ${emotionColors.brands500};
      color: ${emotionColors.brands500};
    }
  }

  &.active {
    color: ${emotionColors.brands500};

    svg {
      fill: ${emotionColors.brands500};
      color: ${emotionColors.brands500};
    }
  }
`;
const LinkText = styled.span<{ isShow: boolean }>(
  (props) => css`
    margin: 0;
    width: 1px;
    text-decoration: none;
    white-space: nowrap;
    opacity: 0;
    transition: all 0.3s ease-out;
    will-change: width, opacity;
    ${props.isShow &&
    css`
      margin: 0 0 0 14px;
      width: auto;
      opacity: 1;
      animation-name: ${showMenuItems};
      animation-duration: 1s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
    `}
  `
);
export const NavigationSidebarMobileItem: React.FC<
  NavigationSidebarLinks & { onClick?: () => void }
> = (props) => {
  return (
    <NavLink
      end
      to={props.url}
      title={props.title}
      css={[asideLinkStyles, asideLinkMobileStyles]}
      onClick={props.onClick}
    >
      <Flex
        as="span"
        p="12px 20px"
        alignItems="center"
        minWidth="48px"
        justify-content="center"
      >
        {props.icon}
        <LinkText isShow={true}>{props.title}</LinkText>
      </Flex>
    </NavLink>
  );
};
