import {
  TExtendedContract,
  TNotificationSetting,
} from '@payler/api/merchant-cabinet';
import { FC, memo, Suspense, useCallback, useState } from 'react';
import { Card, CardContent, CardHead, CardTitle } from '@payler/ui-components';
import { Grid, HStack, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CrossIcon, Pencil24Icon } from '@payler/ui-icons';
import { LabeledValue } from '../LabeledValue/LabeledValue';
import { useShowDeleteNotificationSettingModal } from '../../modals/DeleteNotificationSettingModal';
import EditNotificationSettingForm from '../../forms/notifications/EditNotificationSettingForm';
import { useUserPermissions } from '../../hooks/user/users';

type Props = {
  notification: TNotificationSetting;
  contract: TExtendedContract;
};

type CompProps = Props & { toggleView: VoidFunction };

const gridTemplateColumns = 'minmax(0, 1r)';

const CompView: FC<CompProps> = ({ notification, contract, toggleView }) => {
  const { t } = useTranslation('notifications');

  const triggers = notification.triggers
    .map((x) => t(`notificationKind:${x}`))
    .join(', ');

  return (
    <Card>
      <CardHead>
        <CardTitle>{contract.name}</CardTitle>
        <Buttons id={notification.id} toggleView={toggleView} mode="view" />
      </CardHead>
      <CardContent px={3} py={2}>
        <Grid
          gap={{ base: 2, md: 4 }}
          gridTemplateColumns={gridTemplateColumns}
        >
          <LabeledValue gridColumn="1" label={t('triggers')}>
            {triggers}
          </LabeledValue>
          <LabeledValue label={t('fields:notificationMethod')}>
            {t(`actions.${notification.notificationType}`)}
          </LabeledValue>
          <LabeledValue label={t('recipients')}>
            {notification.notificationReceivers?.join(', ') ?? ''}
          </LabeledValue>
        </Grid>
      </CardContent>
    </Card>
  );
};

const CompEdit = ({ toggleView, notification, contract }: CompProps) => {
  return (
    <Card>
      <CardHead>
        <CardTitle>{contract.name}</CardTitle>
        <Buttons id={notification.id} toggleView={toggleView} mode="edit" />
      </CardHead>
      <CardContent px={3} py={2}>
        <EditNotificationSettingForm
          notification={notification}
          onCancel={toggleView}
          onSubmit={toggleView}
        />
      </CardContent>
    </Card>
  );
};

type TMode = 'view' | 'edit';

export const NotificationSettingCard = (props: Props) => {
  const [mode, setMode] = useState<TMode>('view');
  const toggle = useCallback(
    () => setMode((v) => (v === 'view' ? 'edit' : 'view')),
    []
  );
  const Comp = mode === 'view' ? CompView : CompEdit;
  return (
    <Suspense fallback={null}>
      <Comp {...props} toggleView={toggle} />
    </Suspense>
  );
};

export default NotificationSettingCard;

const Buttons = memo(
  ({
    id,
    toggleView,
    mode,
  }: {
    id: number;
    toggleView: VoidFunction;
    mode: TMode;
  }) => {
    const showDeleteModal = useShowDeleteNotificationSettingModal();
    const cursor = mode === 'view' ? 'pointer' : 'not-allowed';
    const colorEdit = mode === 'view' ? 'primary.300' : 'brands.500';
    const colorDeleteHover = mode === 'view' ? 'red.500' : 'primary.300';
    const { canCreateNotifications } = useUserPermissions();
    if (!canCreateNotifications) return null;
    return (
      <HStack spacing={2}>
        <Icon
          role="button"
          as={Pencil24Icon}
          w={3}
          h={3}
          color={colorEdit}
          cursor={cursor}
          _hover={{ color: 'brands.500' }}
          onClick={mode === 'view' ? toggleView : undefined}
        />
        <Icon
          role="button"
          as={CrossIcon}
          w={3}
          h={3}
          cursor={cursor}
          color="primary.300"
          _hover={{ color: colorDeleteHover }}
          onClick={mode === 'view' ? () => showDeleteModal(id) : undefined}
        />
      </HStack>
    );
  }
);
