import { useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { isNil, omitBy } from 'lodash';
import { getFloatOrUndefined } from '../../helpers/url-params';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { TApmPaymentsURLParams } from './types';
import { TApmPaymentsFiltersForm } from '../../forms/ApmPaymentsFilters/types';
import { assert } from '@payler/utils';

const formValuesToParams = (
  values: TApmPaymentsFiltersForm
): TApmPaymentsURLParams => {
  const tmp: TApmPaymentsURLParams = {
    SessionStatus: values.SessionStatus ? values.SessionStatus : undefined,
    EndDate: values.EndDate ? values.EndDate : undefined,
    StartDate: values.StartDate ? values.StartDate : undefined,
    SessionId: values.SessionId ? values.SessionId : undefined,
    Currency: values.Currency ? values.Currency : undefined,
    PaymentMethod: values.PaymentMethod ? values.PaymentMethod : undefined,
    PaymentType: values.PaymentType ? values.PaymentType : undefined,
    StartAmount: values.StartAmount?.toString() ?? undefined,
    EndAmount: values.EndAmount?.toString() ?? undefined,
    OrderId: values.OrderId ?? undefined,
  };
  return omitBy(tmp, isNil);
};

const queryToFilters = (params: URLSearchParams) => {
  return {
    SessionStatus: params.get('SessionStatus') ?? '',
    EndDate: params.get('EndDate') ?? '',
    StartDate: params.get('StartDate') ?? '',
    SessionId: params.get('SessionId') ?? '',
    Currency: params.get('Currency') ?? '',
    PaymentMethod: params.get('PaymentMethod') ?? '',
    PaymentType: params.get('PaymentType') ?? '',
    StartAmount: getFloatOrUndefined(params.get('StartAmount')),
    EndAmount: getFloatOrUndefined(params.get('EndAmount')),
    OrderId: params.get('OrderId' ?? ''),
  } as TApmPaymentsFiltersForm;
};

export const useSetApmPaymentsUrlSearchParams = () => {
  const [, setParams] = useSearchParams();
  const { PageSize } = usePagerPropsFromUrl();

  return useCallback(
    (values: TApmPaymentsFiltersForm) => {
      const params = formValuesToParams(values);
      if (PageSize !== 10) {
        params.PageSize = PageSize.toString();
      }
      //Page by default is 0
      setParams(params);
    },
    [PageSize, setParams]
  );
};

export const useApmPaymentsFiltersFromUrl = () => {
  const [params] = useSearchParams();
  return useMemo(() => queryToFilters(params), [params]);
};

export const useApmPaymentSessionIdFromUrl = () => {
  const { id } = useParams();
  assert(!!id, 'Session id mast be string');
  return id;
};
