import {
  TFasterPayout,
  TFasterPayoutStatus,
} from '@payler/api/merchant-cabinet';
import {
  Badge,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import {
  ErrorBoundary,
  NoData,
  ScrollBarWithFade,
} from '@payler/ui-components';
import { TableSkeleton } from '../../components/TableSkeleton/TableSkeleton';
import { PaymentDatesCell } from '../PaymentsTable/ReportDateCell';
import { useTimeAgo } from '../../hooks/use-time-ago';
import { AmountWithCurrencyCell } from '../PaymentsTable/AmountWithCurrencyCell';
import { useLocation, useNavigate } from 'react-router-dom';
import { PanAndIdCell } from './PanAndIdCell';
import { useDayjsFormat } from '@payler/utils';
import { BankNameWithLogo } from '../../components/BankNameWithLogo/BankNameWithLogo';
import { TableFooter } from '../components/TableFooter';
import { tableStyleConfig } from '../tableStyleConfig';
import { GroupRow } from '../PaymentsTable/GroupRow';
import {
  useFasterPayoutsQuery,
  useFasterPayoutsQueryPagerProps,
} from '../../hooks/faster-payouts/queries';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';
import { useTranslation } from 'react-i18next';

export const FasterPayoutsTable = () => {
  const props = useFasterPayoutsQueryPagerProps();
  return (
    <>
      <React.Suspense fallback={null}>
        <TableWithData />
      </React.Suspense>
      <ErrorBoundary>
        <React.Suspense fallback={null}>
          <TableFooter paginationProps={props} />
        </React.Suspense>
      </ErrorBoundary>
    </>
  );
};

const TableWithData = () => {
  const { status, data } = useFasterPayoutsQuery();
  switch (status) {
    case 'loading':
      return <TableSkeleton />;
    case 'error':
      return <NoData />;
    case 'success':
    default: {
      const empty = data.items.length === 0;
      if (empty) {
        return <NoData />;
      }
      return (
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Header />
              <Tbody>
                {Object.entries(data.grouped)
                  .sort(([a], [b]) => -a.localeCompare(b))
                  .map(([group, rows]) => (
                    <Rows key={group} group={group} payments={rows} />
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      );
    }
  }
};

const Rows = ({
  group,
  payments,
}: {
  group: string;
  payments: TFasterPayout[];
}) => {
  const format = useDayjsFormat();
  const timeAgo = useTimeAgo();
  return (
    <>
      <tr>
        <GroupRow colSpan={5}>
          {format(group, 'dddd DD MMMM')}
          <Text as="span" color="primary.300">
            &nbsp;&nbsp;&mdash;&nbsp;&nbsp;{timeAgo.format(new Date(group))}
          </Text>
        </GroupRow>
      </tr>
      {payments.map((row) => (
        <Row data={row} key={row.id} />
      ))}
    </>
  );
};

const Row = ({ data }: { data: TFasterPayout }) => {
  const contractId = useURLContractId();
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = () => {
    if (contractId) {
      navigate(`/${contractId}/faster-payouts/${data.id}${location.search}`);
    }
  };
  return (
    <Tr onClick={handleClick}>
      <Td>
        <PaymentDatesCell
          topDate={data.created}
          bottomDate={data.lastOperationDate}
        />
      </Td>
      <Td>
        <Badge variant={getFasterPayoutBadgeVariant(data.status)}>
          {data.statusDescription}
        </Badge>
      </Td>
      <Td>
        <BankNameWithLogo {...data} />
      </Td>
      <Td>
        <PanAndIdCell id={data.merchantOrderId} pan={data.phone} />
      </Td>
      <Td textAlign="right">
        <AmountWithCurrencyCell
          amount={data.amount ?? 0}
          currencyCode={data.currencyCode}
          amountOfRefunds={0}
        />
      </Td>
    </Tr>
  );
};

export function getFasterPayoutBadgeVariant(status: TFasterPayoutStatus) {
  switch (status) {
    case 'Created':
    case 'Pending':
    case 'Ready':
      return 'yellow';
    case 'Success':
      return 'green';
    case 'Rejected':
      return 'red';
    default:
      return undefined;
  }
}

const Header = () => {
  const { t } = useTranslation('tableHeader');
  return (
    <Thead>
      <Tr>
        <Th>{t('period')}</Th>
        <Th>{t('status')}</Th>
        <Th>{t('processingName')}</Th>
        <Th textAlign="right">{t('phoneAndOrderId')}</Th>
        <Th textAlign="right">{t('amount')}</Th>
      </Tr>
    </Thead>
  );
};

export default FasterPayoutsTable;
