export const transactionStatusEn = {
  allStatuses: 'All statuses',
  Authorized: 'Blocked',
  Charged: 'Successful',
  Created: 'Created',
  Credited: 'Credited',
  Pending: 'Pending',
  PendingOnlyCredit: 'Pending',
  PendingOnlyDebit: 'Pending',
  PreAuthorized3DS: 'Awaits 3DS',
  Refunded: 'Refunded',
  Registered: 'Registered',
  Rejected: 'Rejected',
  RejectedOnlyCredit: 'Rejected',
  RejectedOnlyDebit: 'Rejected',
  Reversed: 'Reversed',
  ProviderTechError: 'Provider Tech Error',
};
type TTransactionStatusTranslation = typeof transactionStatusEn;

export const transactionStatusEs: TTransactionStatusTranslation = {
  allStatuses: 'Todos los estados',
  Authorized: 'Bloqueado',
  Charged: 'Exitoso',
  Created: 'Creado',
  Credited: 'Retirada',
  Pending: 'Pendiente',
  PendingOnlyCredit: 'Pendiente',
  PendingOnlyDebit: 'Pendiente',
  PreAuthorized3DS: 'Espera 3DS',
  Refunded: 'Reembolsado',
  Registered: 'Registered',
  Rejected: 'Rechazado',
  RejectedOnlyCredit: 'Rechazado',
  RejectedOnlyDebit: 'Rechazado',
  Reversed: 'Invertido',
  ProviderTechError: 'Error técnico del proveedor',
};

export const transactionStatusRu: TTransactionStatusTranslation = {
  allStatuses: 'Все статусы',
  Authorized: 'Заблокировано',
  Charged: 'Успешно',
  Created: 'Создан',
  Credited: 'Выдано',
  Pending: 'В ожидании',
  PendingOnlyCredit: 'В ожидании',
  PendingOnlyDebit: 'В ожидании',
  PreAuthorized3DS: 'Ожидает 3DS',
  Refunded: 'Возвращено',
  Registered: 'Registered',
  Rejected: 'Отклонено',
  RejectedOnlyCredit: 'Отклонено',
  RejectedOnlyDebit: 'Отклонено',
  Reversed: 'Разблокировано',
  ProviderTechError: 'Техническая ошибка на стороне платежного провайдера',
};
