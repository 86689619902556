import {
  LoginParams,
  MayBe,
  TAnalyticsDataResponse,
  TApmPaymentsQueryParams,
  TApmPaymentsResponse,
  TApmPaymentsTemplate,
  TApplicationUser,
  TBalance,
  TContractPayload,
  TCreateInvoiceRequest,
  TCreateInvoiceResponse,
  TCreateNotificationSetting,
  TCreatePayoutRequest,
  TCreateUserRequest,
  TCreditResponse,
  TCurrencyInfo,
  TCurrentUserInfo,
  TExtendedContract,
  TFasterPaymentsQueryParams,
  TFasterPaymentsResponse,
  TFasterPaymentsTemplate,
  TFasterPaymentWithHistory,
  TFasterPayoutsQueryParams,
  TFasterPayoutsResponse,
  TFasterPayoutsTemplate,
  TFasterPayoutWithHistory,
  TInterfaceLanguage,
  TInvoiceModelPaginationResult,
  TInvoicesRequestParams,
  TInvoiceTemplate,
  TNotificationSetting,
  TPaymentsAnalyticsQueryParams,
  TPaymentsFilters,
  TPaymentsFiltersQueryParams,
  TPaymentTemplate,
  TPayoutModelPaginationResult,
  TPayoutsRequestParams,
  TReport,
  TReportsResponse,
  TTransactionWithHistory,
  TUpdateNotificationSetting,
  TUpdateUserRequest,
  TApmPaymentSession,
  TCreateReportPayloadV2,
  TUpdateReportPayload,
  TCreateReportPayloadV3,
} from './types';
import axios, { AxiosInstance } from 'axios';
import createLogger from 'debug';
import { stringify } from 'query-string';
import { isNil, omitBy } from 'lodash';
import { getDayjsOrNull } from '@payler/utils';

const log = createLogger('api:merchant-cabinet');

const paramsSerializer = (data: object): string => {
  return stringify(data, { arrayFormat: 'none' });
};

export class ApiMerchantCabinet {
  private axios: AxiosInstance;

  constructor(
    private baseURL: string,
    private culture: TInterfaceLanguage,
    private tokenKey = 'token'
  ) {
    this.axios = axios.create({
      baseURL,
      params: {
        culture,
      },
      paramsSerializer,
    });
    this.axios.interceptors.request.use(
      function (config) {
        const token = localStorage.getItem(tokenKey);
        if (token) {
          if (config.headers) {
            config.headers['Authorization'] = `Bearer ${token}`;
          } else {
            config.headers = { Authorization: `Bearer ${token}` };
          }
        }
        return Promise.resolve(config);
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    this.axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const token = localStorage.getItem(tokenKey);
        if (!!token && error.response?.status === 401) {
          localStorage.removeItem(tokenKey);
          window.dispatchEvent(
            new StorageEvent('storage', { key: tokenKey, newValue: null })
          );
        }
        return Promise.reject(error);
      }
    );
  }

  //region apm-payments
  /**
   * Получение списка всех оплат APM с пагинацией и фильтрами
   *
   * @param ContractId
   * @param params
   * @param dateFormat
   */
  async getApmPayments(
    ContractId: number,
    params: TApmPaymentsQueryParams,
    dateFormat = 'DD.MM.YYYY'
  ): Promise<TApmPaymentsResponse> {
    const data = omitBy(
      {
        ContractId,
        ...params,
        StartDate: getDayjsOrNull(params.StartDate, dateFormat)?.format(
          'YYYY-MM-DD'
        ),
        EndDate: getDayjsOrNull(params.EndDate, dateFormat)?.format(
          'YYYY-MM-DD'
        ),
      },
      isNil
    );
    const resp = await this.axios.get('/api/v2/apm-payments', {
      params: data,
    });
    return resp.data;
  }

  /**
   * Получение детализации сессии APM платежа
   *
   * @param sessionId
   */
  async getApmPaymentSession(
    sessionId: string
  ): Promise<{ session: TApmPaymentSession }> {
    const resp = await this.axios.get(`/api/v2/apm-payments/${sessionId}`);
    return resp.data;
  }

  //TODO: Исправить реализацию, когда будет BackEnd
  /**
   * Получение шаблонов фильтров платежей APM
   */
  async getApmPaymentsTemplates(
    contractId: number
  ): Promise<TApmPaymentsTemplate[]> {
    const resp = await this.axios.get('/api/v2/templates/apm-payments', {
      params: { contractId },
    });
    return resp.data;
  }

  /**
   * Создание шаблона фильтра платежей
   * @param filters
   * @param contractId
   * @param dateformat
   */
  async saveApmPaymentsTemplate(
    filters: TApmPaymentsQueryParams,
    contractId: number,
    dateformat = 'DD.MM.YYYY'
  ): Promise<TApmPaymentsTemplate> {
    const data: Omit<TApmPaymentsTemplate, 'id'> = {
      contractId,
      startAmount: filters.StartAmount
        ? Number(filters.StartAmount)
        : undefined,
      endAmount: filters.EndAmount ? Number(filters.EndAmount) : undefined,
      startDate:
        getDayjsOrNull(filters.StartDate, dateformat)?.format('YYYY-MM-DD') ??
        undefined,
      endDate:
        getDayjsOrNull(filters.EndDate, dateformat)?.format('YYYY-MM-DD') ??
        undefined,
      statuses: filters.SessionStatus ?? undefined,
    };
    const resp = await this.axios.post('/api/v2/templates/apm-payments', data);
    return resp.data;
  }

  //endregion

  //region faster-payments
  /**
   * Получение списка всех оплат СБП с пагинацией и фильтрами
   *
   * @param ContractId
   * @param params
   * @param dateFormat
   */
  async getFasterPayments(
    ContractId: number,
    params: TFasterPaymentsQueryParams,
    dateFormat = 'DD.MM.YYYY'
  ): Promise<TFasterPaymentsResponse> {
    const tmp: TFasterPaymentsQueryParams = {
      ...params,
      CreatedAtFrom: getDayjsOrNull(params.CreatedAtFrom, dateFormat)?.format(
        'YYYY-MM-DD'
      ),
      CreatedAtTo: getDayjsOrNull(params.CreatedAtTo, dateFormat)?.format(
        'YYYY-MM-DD'
      ),
      AmountTo: params.AmountTo ? Number(params.AmountTo) * 100 : undefined,
      AmountFrom: params.AmountFrom
        ? Number(params.AmountFrom) * 100
        : undefined,
    };
    const data = omitBy(
      {
        ContractId,
        ...tmp,
      },
      isNil
    );
    const resp = await this.axios.get('/api/v2/faster-payments', {
      params: data,
    });
    return resp.data;
  }

  /**
   * Получение детальной информации по платежу
   *
   * @param contractId - Идентификатор контракта, к которому относится платеж
   * @param id - Идентификатор платежа
   */
  async getFasterPaymentWithHistory(
    contractId: number,
    id: number
  ): Promise<TFasterPaymentWithHistory> {
    const resp = await this.axios.get(`/api/v2/faster-payments/${id}`, {
      params: { contractId },
    });
    return resp.data;
  }

  /**
   * Получение шаблонов фильтров платежей СБП
   */
  async getFasterPaymentsTemplates(
    contractId: number
  ): Promise<TFasterPaymentsTemplate[]> {
    const resp = await this.axios.get('/api/v2/templates/faster-payments', {
      params: { contractId },
    });
    return resp.data;
  }

  /**
   * Создание шаблона фильтра платежей
   * @param filters
   * @param contractId
   * @param dateformat
   */
  async saveFasterPaymentsTemplate(
    filters: TFasterPaymentsQueryParams,
    contractId: number,
    dateformat = 'DD.MM.YYYY'
  ): Promise<TFasterPaymentsTemplate> {
    const data: Omit<TFasterPaymentsTemplate, 'id'> = {
      contractId,
      amountFrom: filters.AmountFrom ? Number(filters.AmountFrom) : undefined,
      amountTo: filters.AmountTo ? Number(filters.AmountTo) : undefined,
      dateFrom:
        getDayjsOrNull(filters.CreatedAtFrom, dateformat)?.format(
          'YYYY-MM-DD'
        ) ?? undefined,
      dateTo:
        getDayjsOrNull(filters.CreatedAtTo, dateformat)?.format('YYYY-MM-DD') ??
        undefined,
      statuses: filters.Statuses ?? undefined,
    };
    const resp = await this.axios.post(
      '/api/v2/templates/faster-payments',
      data
    );
    return resp.data;
  }

  //endregion

  //region faster-payouts
  /**
   * Получение списка всех платежей СБП с пагинацией и фильтрами
   *
   * @param ContractId
   * @param params
   * @param dateFormat
   */
  async getFasterPayouts(
    ContractId: number,
    params?: TFasterPayoutsQueryParams,
    dateFormat = 'DD.MM.YYYY'
  ): Promise<TFasterPayoutsResponse> {
    const tmp = {
      ...params,
      DateFrom: getDayjsOrNull(params?.DateFrom, dateFormat)?.format(
        'YYYY-MM-DD'
      ),
      DateTo: getDayjsOrNull(params?.DateTo, dateFormat)?.format('YYYY-MM-DD'),
    };
    const data = omitBy(
      {
        ContractId,
        ...tmp,
      },
      isNil
    );
    const resp = await this.axios.get('/api/v2/faster-payouts', {
      params: data,
    });
    return resp.data;
  }

  /**
   * Получение детальной информации по платежу
   *
   * @param contractId - Идентификатор контракта, к которому относится платеж
   * @param id - Идентификатор платежа
   */
  async getFasterPayoutWithHistory(
    contractId: number,
    id: number
  ): Promise<TFasterPayoutWithHistory> {
    const resp = await this.axios.get(`/api/v2/faster-payouts/${id}`, {
      params: { contractId },
    });
    return resp.data;
  }

  /**
   * Получение шаблонов фильтров выплат СБП
   */
  async getFasterPayoutsTemplates(
    contractId: number
  ): Promise<TFasterPayoutsTemplate[]> {
    const resp = await this.axios.get('/api/v2/templates/faster-payouts', {
      params: { contractId },
    });
    return resp.data;
  }

  /**
   * Создание шаблона фильтра выплат
   * @param filters
   * @param contractId
   * @param dateformat
   */
  async saveFasterPayoutsTemplate(
    filters: TFasterPayoutsQueryParams,
    contractId: number,
    dateformat = 'DD.MM.YYYY'
  ): Promise<TFasterPayoutsTemplate> {
    const data: Omit<TFasterPayoutsTemplate, 'id'> = {
      contractId,
      amountFrom: filters.AmountFrom ? Number(filters.AmountFrom) : undefined,
      amountTo: filters.AmountTo ? Number(filters.AmountTo) : undefined,
      dateFrom:
        getDayjsOrNull(filters.DateFrom, dateformat)?.format('YYYY-MM-DD') ??
        undefined,
      dateTo:
        getDayjsOrNull(filters.DateTo, dateformat)?.format('YYYY-MM-DD') ??
        undefined,
      currencyCode: filters.Currency ?? undefined,
      statuses: filters.Statuses ?? undefined,
    };
    const resp = await this.axios.post(
      '/api/v2/templates/faster-payouts',
      data
    );
    return resp.data;
  }

  //endregion

  //region notifications
  /**
   * Список настроек уведомлений
   */
  async getNotificationSettings(
    contractIds?: number[]
  ): Promise<TNotificationSetting[]> {
    const resp = await this.axios.get('/api/v2/notification-settings', {
      params: { contractIds },
    });
    return resp.data;
  }

  /**
   * Создание настроек уведомлений
   * @param data
   */
  async createNotificationSetting(
    data: TCreateNotificationSetting
  ): Promise<TNotificationSetting> {
    const resp = await this.axios.post('/api/v2/notification-settings', data);
    return resp.data;
  }

  /**
   * Удаление настроек уведомлений
   * @param id
   */
  async deleteNotificationSetting(id: number): Promise<boolean> {
    const resp = await this.axios.delete(`/api/v2/notification-settings/${id}`);
    return resp.status === 200;
  }

  /**
   * Изменение настроек уведомлений
   * @param id
   * @param data
   */
  async updateNotificationSetting(
    id: number,
    data: TUpdateNotificationSetting
  ): Promise<TNotificationSetting> {
    const resp = await this.axios.put(
      `/api/v2/notification-settings/${id}`,
      data
    );
    return resp.data;
  }

  //endregion

  //region users
  /**
   * Получить информацию о пользователе
   */
  async getUserInfo(): Promise<TCurrentUserInfo> {
    const resp = await this.axios.get('/api/v2/users/current');
    return resp.data;
  }

  /**
   * Обновить язык пользователя
   */
  async updateUserLanguage(
    lang: TInterfaceLanguage
  ): Promise<TCurrentUserInfo['user']> {
    const resp = await this.axios.put(`/api/v2/users/language`, {
      interfaceLanguage: lang,
    });
    return resp.data;
  }

  /**
   * Обновить пароль пользователя
   */
  async updateUserPassword(
    currentPassword: string,
    newPassword: string
  ): Promise<{ token: string; message: string }> {
    const resp = await this.axios.post(`/api/v2/auth/password/change`, {
      currentPassword,
      newPassword,
    });
    return resp.data;
  }

  /**
   * Создание нового пользователя
   */
  async createUser(data: TCreateUserRequest): Promise<TApplicationUser> {
    const resp = await this.axios.post('/api/v2/users', data);
    return resp.data;
  }

  /**
   * Обновление данных пользователя
   */
  async updateUser(
    id: string,
    data: TUpdateUserRequest
  ): Promise<TApplicationUser> {
    const resp = await this.axios.put(`/api/v2/users/${id}`, data);
    return resp.data;
  }

  /**
   * Удаление пользователя
   * @param id
   */
  async deleteUser(id: string): Promise<string> {
    const resp = await this.axios.delete(`/api/v2/users/${id}`);
    return resp.data.message;
  }

  //endregion

  //region reports
  /**
   * Создать отчёт версии 2
   * @param data
   * @param dateFormat
   */
  async createReportV2(
    data: TCreateReportPayloadV2,
    dateFormat = 'DD.MM.YYYY'
  ) {
    const tmp: TCreateReportPayloadV2 = {
      ...data,
      startDate: getDayjsOrNull(data.startDate, dateFormat)?.format(
        'YYYY-MM-DD'
      ),
      endDate: getDayjsOrNull(data.endDate, dateFormat)?.format('YYYY-MM-DD'),
    };
    const resp = await this.axios.post('/api/v2/reports', tmp);
    if (![201, 202].includes(resp.status)) {
      throw new Error('createReport failed');
    }
    return resp.data;
  }

  /**
   * Создать отчёт версии 3
   * @param data
   * @param dateFormat
   */
  async createReportV3(
    data: TCreateReportPayloadV3,
    dateFormat = 'DD.MM.YYYY'
  ) {
    const { startDate, endDate } = data.filter;
    const tmp: TCreateReportPayloadV3 = {
      ...data,
      filter: {
        ...data?.filter,
        startDate: getDayjsOrNull(startDate, dateFormat)?.format('YYYY-MM-DD'),
        endDate: getDayjsOrNull(endDate, dateFormat)?.format('YYYY-MM-DD'),
      },
    };
    const resp = await this.axios.post('/api/v3/reports', tmp);
    if (![201, 202].includes(resp.status)) {
      throw new Error('createReport failed');
    }
    return resp.data;
  }

  /**
   * Обновить отчёт
   * @param id
   * @param payload
   */
  async updateReport(
    id: number,
    payload: TUpdateReportPayload
  ): Promise<TReport> {
    const resp = await this.axios.put(`/api/v2/reports/${id}`, payload);
    return resp.data;
  }

  /**
   * Данные отчёта по id
   * @param id
   */
  async getReport(id: number): Promise<TReport> {
    const resp = await this.axios.get(`/api/v2/reports/${id}`);
    if (resp.status !== 200) {
      throw new Error('get report failed');
    }
    return resp.data;
  }

  /**
   * Список отчётов и данные пагинации
   * @param Page
   * @param PageSize
   */
  async getReports(Page = 1, PageSize = 10): Promise<TReportsResponse> {
    const resp = await this.axios.get('/api/v2/reports', {
      params: { Page, PageSize },
    });
    return resp.data;
  }

  /**
   * Удалить отчёт
   * @param id
   */
  async deleteReport(id: number): Promise<boolean> {
    const resp = await this.axios.delete(`/api/v2/reports/${id}`);
    if (resp.status !== 200) {
      log('deleteReport response %O', resp);
    }
    return resp.status === 200;
  }

  /**
   * Скачать файл отчёта
   * @param id
   * @param type
   * @param reportName
   * @param culture
   */
  async downloadReport(
    id: number,
    type: 'xls' | 'csv',
    reportName?: string,
    culture: TInterfaceLanguage = this.culture
  ) {
    const name = `${id}/${type === 'xls' ? 'xlsx' : 'csv'}`;
    const filename = `${reportName ?? id}.${type === 'xls' ? 'xlsx' : 'csv'}`;
    const url = `/api/v2/reports/${name}/?culture=${culture}`;
    const { data } = await this.axios.get(url, { responseType: 'blob' });

    this.downloadFile(data, filename);
  }

  //endregion

  //region payments
  /**
   * Получение данных платежа
   * @param id
   */
  async getPayment(id: number): Promise<TTransactionWithHistory> {
    const resp = await this.axios.get(`/api/v2/payments/${id}`);
    return resp.data;
  }

  /**
   * СкачатьPDF файла платежа
   * @param id
   * @param filename
   * @param culture
   */
  async downloadPaymentPDF(
    id: number,
    filename: string,
    culture: TInterfaceLanguage = this.culture
  ) {
    const { data } = await this.axios.get(
      `/api/v2/payments/${id}/pdf?culture=${culture}`,
      { responseType: 'blob' }
    );
    this.downloadFile(data, filename);
  }

  /**
   * Списание суммы через ядро
   *
   * @param id - id платежа
   * @param amount - рубли
   * @param unitRate - отношение базовой валюты к разменной единице валюты
   */
  async chargePayment(id: number, amount: number, unitRate: number) {
    const data = { amount: Math.round(amount * unitRate) };
    const resp = await this.axios.post<{ orderId: string; amount: number }>(
      `/api/v2/payments/${id}/charge`,
      data
    );
    return resp.data;
  }

  /**
   * Возврат суммы через ядро
   *
   * @param id - id платежа
   * @param amount - рубли
   * @param password - платёжный пароль
   * @param unitRate - отношение базовой валюты к разменной единице валюты
   */
  async refundPayment(
    id: number,
    amount: number,
    password: string,
    unitRate: number
  ) {
    const data = { amount: Math.round(amount * unitRate), password };
    const resp = await this.axios.post<{ orderId: string; amount: number }>(
      `/api/v2/payments/${id}/refund`,
      data
    );
    return resp.data;
  }

  /**
   * Разблокировка суммы через ядро
   *
   * @param id - id платежа
   * @param amount - рубли
   * @param unitRate - отношение базовой валюты к разменной единице валюты
   */
  async retrievePayment(id: number, amount: number, unitRate: number) {
    const data = { amount: Math.round(amount * unitRate) };
    const resp = await this.axios.post<{ orderId: string; amount: number }>(
      `/api/v2/payments/${id}/retrieve`,
      data
    );
    return resp.data;
  }

  /**
   * Получение списка платежей
   * @param ContractId
   * @param filters
   * @param dateFormat
   */
  async getPayments(
    ContractId: number,
    filters: TPaymentsFiltersQueryParams,
    dateFormat = 'DD.MM.YYYY'
  ): Promise<TPayoutModelPaginationResult> {
    const params = { ContractId, ...filters };
    params.StartDate = getDayjsOrNull(params.StartDate, dateFormat)?.format(
      'YYYY-MM-DD'
    );
    params.EndDate = getDayjsOrNull(params.EndDate, dateFormat)?.format(
      'YYYY-MM-DD'
    );
    const cleanParams = omitBy({ ...params }, isNil);
    log('params: %O', cleanParams);
    const resp = await this.axios.get('/api/v2/payments', {
      params: cleanParams,
    });
    return resp.data;
  }

  /**
   * Получение шаблонов фильтров платежей
   */
  async getPaymentsTemplates(contractId: number): Promise<TPaymentTemplate[]> {
    const resp = await this.axios.get('/api/v2/templates/payments', {
      params: { contractId },
    });
    return resp.data;
  }

  /**
   * Удаление шаблона
   * @param id
   */
  async deleteTemplate(id: number): Promise<boolean> {
    const resp = await this.axios.delete(`/api/v2/templates/${id}`);
    return resp.status === 200;
  }

  /**
   * Создание шаблона фильтра платежей
   * @param filters
   * @param ContractId
   * @param dateFormat
   */
  async savePaymentsTemplate(
    filters: Omit<TPaymentsFilters, 'ContractId'>,
    ContractId: number,
    dateFormat = 'DD.MM.YYYY'
  ): Promise<TPaymentTemplate> {
    const data: Omit<TPaymentTemplate, 'id'> & { ContractId: number } = {
      ContractId,
      startAmount: filters.StartAmount
        ? Number(filters.StartAmount)
        : undefined,
      endAmount: filters.EndAmount ? Number(filters.EndAmount) : undefined,
      startDate: getDayjsOrNull(filters.StartDate, dateFormat)?.format(
        'YYYY-MM-DD'
      ),
      endDate: getDayjsOrNull(filters.EndDate, dateFormat)?.format(
        'YYYY-MM-DD'
      ),
      transactionStatuses: filters.TransactionStatuses ?? [],
      paymentMethods: filters.PaymentMethods ?? [],
      currencyCodes: filters.CurrencyCodes ?? [],
      paymentIndicators: filters.PaymentIndicators ?? [],
      isAntifraudDeclined: filters.IsAntifraudDeclined ?? undefined,
    };
    const resp = await this.axios.post('/api/v2/templates/payments', data);
    return resp.data;
  }

  //endregion

  // region authorization

  /**
   * Логин пользователя
   * @param email - Емейл
   * @param password - Пароль
   * @param captcha - результат от googleReCaptcha
   */
  async login({
    email,
    password,
    captcha,
  }: LoginParams): Promise<{ token: string }> {
    const resp = await this.axios.post('/api/v2/auth/login', {
      email,
      password,
      captcha,
    });
    return resp.data;
  }

  /**
   * Сбросить пароль
   */
  async resetPassword(email: string): Promise<{ message: string }> {
    const resp = await this.axios.post('/api/v2/auth/password/recovery', {
      email,
    });
    return resp.data;
  }

  /**
   * Обновление токена для текущего пользователя
   */
  async refreshToken(): Promise<{
    token?: MayBe<string>;
    message?: MayBe<string>;
  }> {
    const resp = await this.axios.post('/api/v2/auth/refresh');
    return resp.data;
  }

  /**
   * Установка нового пароля (при сбросе или при подтверждении регистрации)
   */
  async submitNewPassword(
    params: {
      userId: string;
      code: string;
      password: string;
    },
    mode: 'confirm' | 'reset' = 'reset'
  ): Promise<{
    token: MayBe<string>;
    message: MayBe<string>;
  }> {
    const resp = await this.axios.post(`/api/v2/auth/password/${mode}`, params);
    return resp.data;
  }

  // endregion

  //region payouts
  /**
   * Создание шаблона фильтра платежей
   * @param filters
   * @param contractId
   * @param dateFormat
   */
  async savePayoutsTemplate(
    filters: Omit<TPaymentsFilters, 'ContractId'>,
    contractId: number,
    dateFormat = 'DD.MM.YYYY'
  ): Promise<TPaymentTemplate> {
    const data: Omit<
      TPaymentTemplate,
      'id' | 'paymentIndicators' | 'paymentMethods'
    > & { contractId: number } = {
      contractId,
      startAmount: filters.StartAmount
        ? Number(filters.StartAmount)
        : undefined,
      endAmount: filters.EndAmount ? Number(filters.EndAmount) : undefined,
      startDate:
        getDayjsOrNull(filters.StartDate, dateFormat)?.format('YYYY-MM-DD') ??
        undefined,
      endDate:
        getDayjsOrNull(filters.EndDate, dateFormat)?.format('YYYY-MM-DD') ??
        undefined,
      transactionStatuses: filters.TransactionStatuses ?? [],
      currencyCodes: filters.CurrencyCodes ?? [],
    };
    const resp = await this.axios.post('/api/v2/templates/payouts', data);
    return resp.data;
  }

  /**
   * Получение шаблонов фильтров платежей
   */
  async getPayoutsTemplates(contractId: number): Promise<TPaymentTemplate[]> {
    const resp = await this.axios.get('/api/v2/templates/payouts', {
      params: { contractId },
    });
    return resp.data;
  }

  /**
   * Список выплат по контракту.
   * Аналог списка платежей, но в этом методе отдается только выплаты(успешные,отклоненные,в ожидании)
   *
   * @param params
   * @param dateFormat
   */
  async getPayouts(
    params: TPayoutsRequestParams,
    dateFormat = 'DD.MM.YYYY'
  ): Promise<TPayoutModelPaginationResult> {
    const data = {
      ...params,
      StartDate: getDayjsOrNull(params.StartDate, dateFormat)?.format(
        'YYYY-MM-DD'
      ),
      EndDate: getDayjsOrNull(params.EndDate, dateFormat)?.format('YYYY-MM-DD'),
      StartAmount: params.StartAmount ? params.StartAmount : undefined,
      EndAmount: params.EndAmount ? params.EndAmount : undefined,
    };
    const resp = await this.axios.get('/api/v2/payouts', { params: data });
    return resp.data;
  }

  /**
   * Создание выплаты
   * @param params
   * @param unitRate - отношение базовой валюты к разменной единице валюты
   */
  async createPayout(
    params: TCreatePayoutRequest,
    unitRate: number
  ): Promise<TCreditResponse> {
    const data = { ...params, amount: Math.round(params.amount * unitRate) };
    const resp = await this.axios.post('/api/v2/payouts', data);
    return resp.data;
  }

  //endregion

  //region invoices
  /**
   * Выставить счёт
   * @param params
   * @param unitRate - отношение базовой валюты к разменной единице валюты
   */
  async createInvoice(
    params: TCreateInvoiceRequest,
    unitRate: number
  ): Promise<TCreateInvoiceResponse> {
    const data: TCreateInvoiceRequest = {
      ...params,
      amount: Math.round(params.amount * unitRate),
    };
    const resp = await this.axios.post('/api/v2/invoices', data);
    return resp.data;
  }

  /**
   * Переотправка счета клиенту
   * @param id
   */
  async resendInvoice(id: number): Promise<string> {
    const resp = await this.axios.post<{ message: string }>(
      `/api/v2/invoices/${id}/resends`
    );
    return resp.data.message;
  }

  /**
   * Список счетов(по контракту), по которым была выставлена ссылка на оплату через ЛК.
   * @param ContractId
   * @param params
   */
  async getInvoices(
    ContractId: number,
    params: Omit<TInvoicesRequestParams, 'ContractId'>
  ): Promise<TInvoiceModelPaginationResult> {
    const resp = await this.axios.get('/api/v2/invoices', {
      params: { ...params, Sort: 'Desc', ContractId },
    });
    return resp.data;
  }

  /**
   * Список шаблонов создания счетов
   */
  async getInvoicesTemplate(contractId: number): Promise<TInvoiceTemplate[]> {
    const resp = await this.axios.get('/api/v2/templates/invoices', {
      params: { contractId },
    });
    return resp.data;
  }

  /**
   * Сохранить шаблон счёта
   * @param template
   * @param contractId
   */
  async saveInvoicesTemplate(
    template: Omit<TInvoiceTemplate, 'id'>,
    contractId: number
  ): Promise<TInvoiceTemplate> {
    const data = { contractId, ...template };
    const resp = await this.axios.post('/api/v2/templates/invoices', data);
    return resp.data;
  }

  //endregion

  //region Contracts
  /**
   * Список контрактов
   */
  async getContracts(): Promise<TExtendedContract[]> {
    const resp = await this.axios.get('/api/v2/contracts');
    return resp.data.contracts;
  }

  /**
   * Получение балансов
   */
  async getBalances(contractId: number): Promise<TBalance[]> {
    const resp = await this.axios.get(
      `/api/v2/contracts/${contractId}/balances`
    );
    return resp.data;
  }

  /**
   * Обновление мерчанта
   */
  async updateContract(
    contractId: number,
    payload: TContractPayload
  ): Promise<TExtendedContract> {
    const resp = await this.axios.put(
      `/api/v2/contracts/${contractId}`,
      payload
    );
    return resp.data;
  }

  /**
   * Форматированные данные для аналитики
   * @param params
   */
  async getPaymentsAnalytics(
    params: TPaymentsAnalyticsQueryParams
  ): Promise<TAnalyticsDataResponse> {
    const resp = await this.axios.get('/api/v2/analytics/payments', { params });
    return resp.data;
  }

  //endregion

  /**
   * Получить словарь валют
   */
  async getCurrenciesInfo(): Promise<TCurrencyInfo[]> {
    const resp = await this.axios.get('/api/v2/currencies');
    return resp.data;
  }

  private downloadFile(data: Blob, filename?: string) {
    const downloadLink = document.createElement('a');
    const downloadUrl = window.URL.createObjectURL(data);
    downloadLink.href = downloadUrl;

    if (filename) {
      downloadLink.download = filename;
    }

    downloadLink.click();

    window.URL.revokeObjectURL(downloadUrl);
  }
}
