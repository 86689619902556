export const titlesEn = {
  alerts: 'Alerts',
  invoices: 'Invoice',
  withdrawals: 'Withdrawals',
  authorization: 'Authorization',
  login: 'Login',
  forgotPassword: 'Forgot your password?',
  recovery: 'Authorization',
  analytics: 'Analytics',
  withdraw: 'Withdraw',
  payments: 'Payments',
  reports: 'Reports',
  settings: 'Settings',
  payment: 'Payment details',
  fasterPayouts: 'Payouts FPS',
  fasterPayments: 'Payments FPS',
  fasterPayout: 'Payment details FPS',
  apmPayments: 'Payments APM',
  notFound: 'Page not found',
  paymentSessionDetails: 'Payment session details',
  errorPage: 'Error',
};

type TitlesTranslation = typeof titlesEn;

export const titlesEs: TitlesTranslation = {
  alerts: 'Alertas',
  invoices: 'Facturación',
  withdrawals: 'Retirada',
  authorization: 'Autorización',
  login: 'Inicie sesión en su cuenta',
  forgotPassword: '¿Olvidó su contraseña?',
  recovery: 'Autorización',
  analytics: 'Analítica',
  withdraw: 'Hacer un pago',
  payments: 'Pagos',
  reports: 'Informes',
  settings: 'Configuraciones',
  payment: 'Detalles de pago',
  fasterPayouts: 'Pagos FPS',
  fasterPayments: 'Pagos FPS',
  fasterPayout: 'Detalles de pago FPS',
  notFound: 'Página no encontrada',
  apmPayments: 'Pagos APM',
  //todo: Эта локализация удалится
  paymentSessionDetails: 'Payment session details',
  errorPage: 'Error',
};

export const titlesRu: TitlesTranslation = {
  alerts: 'Оповещения',
  invoices: 'Выставить счет',
  withdrawals: 'Выплаты',
  authorization: 'Авторизация',
  login: 'Войти в личный кабинет',
  forgotPassword: 'Забыли пароль?',
  recovery: 'Авторизация',
  analytics: 'Аналитика',
  withdraw: 'Сделать выплату',
  payments: 'Платежи',
  reports: 'Отчеты',
  settings: 'Настройки',
  payment: 'Данные платежа',
  fasterPayouts: 'Выплаты СБП',
  fasterPayments: 'Платежи СБП',
  fasterPayout: 'Данные платежа СБП',
  notFound: 'Страница не найдена',
  apmPayments: 'Платежи APM',
  paymentSessionDetails: 'Детализация платёжной сессии',
  errorPage: 'Ошибка',
};
