import {
  TApmPaymentStatus,
  TApmPaymentType,
  TApmPayoutCurrency,
  TFasterPaymentStatus,
  TFasterPayoutCurrency,
  TFasterPayoutStatus,
  TPaymentIndicators,
  TPaymentMethods,
  TTransactionStatus,
} from '@payler/api/merchant-cabinet';

/**
 * Все доступные языки
 */
export const ALL_LANGS = ['en', 'es', 'ru'];

/**
 * Все доступные признаки платежа
 */
export const GLOBAL_PAYMENT_INDICATORS: TPaymentIndicators[] = [
  'IsRecurrentPay',
  'IsBillPay',
  'IsPartRefunded',
  // 'IsAntifraudDeclined', TODO: разобраться почему нужно отключать и для чего отдельный раздел
  'IsApplePay',
  'IsGooglePay',
];

const RU_PAYMENT_INDICATORS: TPaymentIndicators[] = ['IsYandexPay'];

export const ALL_PAYMENT_INDICATORS: TPaymentIndicators[] = [
  ...GLOBAL_PAYMENT_INDICATORS,
  ...RU_PAYMENT_INDICATORS,
];

/**
 * Все статусы платежа
 */
export const ALL_PAYMENT_TRANSACTION_STATUSES: TTransactionStatus[] = [
  'Created',
  'PreAuthorized3DS',
  'Authorized',
  'Reversed',
  'Charged',
  'Refunded',
  'Rejected',
  'Pending',
  'Credited',
  'Registered',
  'RejectedOnlyDebit',
  'RejectedOnlyCredit',
  'PendingOnlyDebit',
  'PendingOnlyCredit',
];

/**
 * Статусы платежей для формы создания отчёта
 */
export const NEW_REPORT_DEBIT_STATUSES: TTransactionStatus[] = [
  'Charged',
  'Authorized',
  'Refunded',
  'RejectedOnlyDebit',
];

/**
 * Статусы выплат для формы создания отчёта
 */
export const NEW_REPORT_CREDIT_STATUSES: TTransactionStatus[] = [
  'Credited',
  'RejectedOnlyCredit',
];

/**
 * Статусы фильтра платежей для платежей
 */
export const PAYMENT_FILTERS_DEBIT_STATUSES: TTransactionStatus[] = [
  'Charged',
  'PreAuthorized3DS',
  'Reversed',
  'Authorized',
  'Refunded',
  'RejectedOnlyDebit',
];

/**
 * Статусы фильтра платежей для выплат
 */
export const PAYMENT_FILTERS_CREDIT_STATUSES: TTransactionStatus[] = [
  'PendingOnlyCredit',
  'Credited',
  'RejectedOnlyCredit',
];

/**
 * Статусы для фильтров СБП
 */
export const FPS_FILTERS_STATUS_OPTIONS: TFasterPayoutStatus[] = [
  'Success',
  'Rejected',
  'Pending',
];

/**
 * Статусы для фильтров платежей СБП
 */
export const FASTER_PAYMENTS_STATUS_OPTIONS: TFasterPaymentStatus[] = [
  'Pending',
  'Failed',
  'Created',
  'Completed',
  'Returned',
];

/**
 * Валюты для фильтров СБП
 */
export const FPS_CURRENCY_OPTIONS: TFasterPayoutCurrency[] = ['RUB'];

/**
 * Способы оплаты в фильтрах платежей
 */
export const PAYMENT_FILTERS_PAYMENT_METHODS: TPaymentMethods[] = ['Card'];

/**
 * Определённый статус выставленного счёта
 */
export const INVOICE_TERMINATE_STATUS: TTransactionStatus[] = [
  'Charged', //Успешно
  'Refunded', //Возвращено
  'Authorized', //Заблокировано
  'Reversed', //Разблокировано
];

export const DEFAULT_PAGER_PROPS = {
  currentPage: 0,
  pageSize: 10,
  totalRecords: 0,
  totalPages: 0,
};

export const PASSWORD_REGEXP = new RegExp(
  /^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{8,}$/
);

export const APM_PAYMENTS_STATUS_OPTIONS: TApmPaymentStatus[] = [
  'Created',
  'Pending',
  'Authorized',
  'Rejected',
  'Refunded',
  'ProviderTechError',
];

export const APM_PAYMENTS_NEW_REPORT_STATUS_OPTIONS: TApmPaymentStatus[] = [
  'Created',
  'Pending',
  'Authorized',
  'Rejected',
  'Refunded',
  'ProviderTechError',
  'PreAuthenticatedThreeDSMethod',
  'PreAuthenticatedRedirect',
  'PreAuthorized',
  'Canceled',
] as const;

export const APM_CURRENCY_OPTIONS: TApmPayoutCurrency[] = [
  'EUR',
  'RUB',
  'USD',
  'CAD',
  'BRL',
  'AED',
  'INR',
  'AZN',
  'GBP',
  'AUD',
  'ARS',
  'JPY',
  'NGN',
  'IDR',
  'CNY',
  'KRW',
  'THB',
  'VND',
  'MYR',
  'NZD',
  'TRY',
];

export const APM_PAYMENTS_INDICATOR: TApmPaymentType[] = ['Payment', 'Payout'];

/**
 * Значение по умолчанию для поля `Вид платёжной страницы`
 */
export const DEFAULT_PAYMENT_PAGE = 'default';
