import { TReport } from '@payler/api/merchant-cabinet';
import { FullWidthBlock } from '../../layouts';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { DeleteReportButton } from './DeleteReportButton';
import { ReportDateCell } from './ReportDateCell';
import { ReportStatusCell } from './ReportStatusCell';
import { DownloadReportButtons } from './DownloadReportButtons';
import { EditReportNameCell } from './EditReportNameCell';
import { NoData, ScrollBarWithFade } from '@payler/ui-components';
import { TableFooter } from '../components/TableFooter';
import { tableStyleConfig } from '../tableStyleConfig';
import TableSkeleton from '../../components/TableSkeleton/TableSkeleton';
import { useReportStatusRefresh } from '../../hooks/reports/misc';
import {
  useReportsQuery,
  useReportsQueryPagerProps,
} from '../../hooks/reports/queries';
import { useTranslation } from 'react-i18next';

const style = {
  ...{
    baseStyle: {
      ...tableStyleConfig.baseStyle,
      tr: {
        ...(tableStyleConfig.baseStyle.tr ?? {}),
        cursor: 'default',
      },
    },
  },
};

export const ReportsTable = () => {
  const { status, data } = useReportsQuery();
  const pagerProps = useReportsQueryPagerProps();
  if (status === 'loading') {
    return <TableSkeleton />;
  }
  if (status === 'error') {
    return <NoData />;
  }

  if (status === 'success' && data.items.length === 0) {
    return <NoData />;
  }

  return (
    <FullWidthBlock bg="secondary.500" borderRadius={{ base: 0, sm: 1.5 }}>
      <ScrollBarWithFade>
        <TableContainer position="relative">
          <Table styleConfig={style}>
            <Header />
            <Tbody>
              {data.items.map((row) => (
                <Row data={row} key={row.id} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </ScrollBarWithFade>
      <TableFooter paginationProps={pagerProps} />
    </FullWidthBlock>
  );
};

const Row = ({ data }: { data: TReport }) => {
  useReportStatusRefresh(data);
  return (
    <Tr>
      <Td>
        <ReportDateCell date={data.creationDate} />
      </Td>
      <Td>
        <ReportStatusCell status={data.status} />
      </Td>
      <Td textAlign="center" width="30%">
        <EditReportNameCell report={data} />
      </Td>
      <Td>
        <DownloadReportButtons id={data.id} reportName={data.name} />
      </Td>
      <Td textAlign="right">
        <DeleteReportButton id={data.id} />
      </Td>
    </Tr>
  );
};

const Header = () => {
  const { t } = useTranslation('tableHeader');
  return (
    <Thead>
      <Tr>
        <Th>{t('created')}</Th>
        <Th>{t('status')}</Th>
        <Th textAlign="center">{t('nameAndAuthor')}</Th>
        <Th>{t('download')}</Th>
        <Th textAlign="right">{t('delete')}</Th>
      </Tr>
    </Thead>
  );
};
