export const analyticsEn = {
  weekNum: '{{week}}w.',
  weekNumYear: '{{week}} week, {{year}} year',
  periodWeek: 'Last week',
  periodMonth: 'Last month',
  period3Month: 'Last 3 months',
  periodNotSet: 'Choose period',
  period: 'Date range',
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
  title: 'Analytics',
  contracts: 'Contract',
  allContractsSelected: 'All contracts selected',
  sumByStatusTitle: 'Daily totals for period',
  averageChargeTitle: 'Average payment',
  averageCharge: 'Average payment',
  sumPieChartTitle: 'Amounts for the period',
  countPieChartTitle: 'Transactions for the period',
  totalSum: 'Total',
  totalTransactions: 'Transactions',
  transactions_zero: '{{count}} transaction',
  transactions_one: '{{count}} transaction',
  transactions_two: '{{count}} transactions',
  transactions_few: '{{count}} transactions',
  transactions_many: '{{count}} transactions',
  transactions_other: '{{count}} transactions',
};

type TAnalyticsTranslation = typeof analyticsEn;

export const analyticsEs: TAnalyticsTranslation = {
  weekNum: '{{week}}sem.',
  weekNumYear: '{{week}} semana {{year}}',
  periodWeek: 'La semana pasada',
  periodMonth: 'El mes pasado',
  period3Month: 'Últimos 3 meses',
  periodNotSet: 'Elegir período',
  period: 'Rango de fechas',
  days: 'Días',
  weeks: 'Semanas',
  months: 'Meses',
  title: 'Analítica',
  contracts: 'Contrato',
  allContractsSelected: 'Todos los contratos seleccionados',
  sumByStatusTitle: 'Totales diarios para el período',
  averageChargeTitle: 'Pago promedio',
  averageCharge: 'Pago promedio',
  sumPieChartTitle: 'Importes del periodo',
  countPieChartTitle: 'Transacciones por un período',
  totalSum: 'Total',
  totalTransactions: 'Transacciones',
  transactions_zero: '{{count}} transacción',
  transactions_one: '{{count}} transacción',
  transactions_two: '{{count}} transacciones',
  transactions_few: '{{count}} transacciones',
  transactions_many: '{{count}} transacciones',
  transactions_other: '{{count}} transacciones',
};

export const analyticsRu: TAnalyticsTranslation = {
  weekNum: '{{week}}нед.',
  weekNumYear: '{{week}} неделя {{year}} года',
  periodWeek: 'Последняя неделя',
  periodMonth: 'Последний месяц',
  period3Month: 'Последние три месяца',
  periodNotSet: 'Выберите период',
  period: 'Период',
  days: 'Дни',
  weeks: 'Недели',
  months: 'Месяцы',
  title: 'Аналитика',
  contracts: 'Контракт',
  allContractsSelected: 'Выбраны все контракты',
  sumByStatusTitle: 'Ежедневные итоги за период',
  averageChargeTitle: 'Средний платёж',
  averageCharge: 'Средний платёж',
  sumPieChartTitle: 'Суммы за период',
  countPieChartTitle: 'Транзакции за период',
  totalSum: 'Всего',
  totalTransactions: 'Транзакции',
  transactions_zero: '{{count}} транзакция',
  transactions_one: '{{count}} транзакция',
  transactions_two: '{{count}} транзакции',
  transactions_few: '{{count}} транзакции',
  transactions_many: '{{count}} транзакций',
  transactions_other: '{{count}} транзакций',
};
