import { useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { FasterPayoutsFiltersForm } from '../forms/FasterPayoutsFilters/FasterPayoutsFiltersForm';
import { PaylerModal } from '@payler/ui-components';

const fasterPayoutsModalOpenedAtom = atom({
  key: createRecoilKey('fasterPayoutsModalOpenedAtom'),
  default: false,
});

export const useShowFasterPayoutsModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(fasterPayoutsModalOpenedAtom, true);
      },
    []
  );

export const useCloseFasterPayoutsModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(fasterPayoutsModalOpenedAtom, false);
      },
    []
  );

export const FasterPayoutsFiltersModal = () => {
  const visible = useBreakpointValue({ base: true, lg: false });
  const open = useRecoilValue(fasterPayoutsModalOpenedAtom);
  const close = useCloseFasterPayoutsModal();
  if (!visible) return null;
  return (
    <PaylerModal close={close} open={open}>
      <FasterPayoutsFiltersForm />
    </PaylerModal>
  );
};
