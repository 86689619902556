import React from 'react';
import { Box, Breadcrumb, HStack, VStack } from '@chakra-ui/react';
import { FullWidthBlock, PageTitle } from '../layouts';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { breakpoints } from '@payler/ui-theme';
import { NoData, Skeleton } from '@payler/ui-components';
import ReactNumberFormat from 'react-number-format';
import TableSkeleton from '../components/TableSkeleton/TableSkeleton';
import FasterPayoutOperationsTable from '../tables/FasterPayoutOperationsTable/FasterPayoutOperationsTable';
import FasterPayoutHistory from '../components/FasterPayoutHistory/FasterPayoutHistory';
import FasterPayoutData from '../components/FasterPayoutData/FasterPayoutData';
import { useFasterPayoutDataQuery } from '../hooks/faster-payouts/queries';
import { useCurrencyCallbacks } from '../hooks/currencies-info';
import { Helmet } from 'react-helmet-async';
import { useURLContractId } from '../layouts/ContractIdLayout/ContractIdLayout';
import { BreadcrumbBackButton } from '../components/BreadcrumbBackButton/BreadcrumbBackButton';

const Wrap = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: 'data' 'operations' 'history';
  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 320px;
    grid-template-areas: 'data history' 'operations history' '. history';
  }
`;

const PageSkeleton = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={t('titles:fasterPayout')} />
      <Skeleton h={4} w="200px" m={3} />
      <Wrap>
        <Box gridArea="data">
          <TableSkeleton />
        </Box>
        <Box gridArea="history">
          <Skeleton w={30} h={2} mb={2} />
          <Skeleton w={60} h={2} mb={2} />
          <Skeleton w={30} h={2} mb={2} />
          <Skeleton w={70} h={2} mb={2} />
        </Box>
      </Wrap>
    </>
  );
};

export const FasterPayoutPage: React.FC = () => {
  const { id: routerId } = useParams<{ id?: string }>();
  const id = Number(routerId);
  const validId = id !== undefined && !isNaN(id);
  return (
    <>
      {validId && <Header id={id} />}
      {validId ? <Contents id={id} /> : <NoData />}
    </>
  );
};

const Contents = ({ id }: { id: number }) => {
  const { status, data } = useFasterPayoutDataQuery(id);
  switch (status) {
    case 'error':
      return <NoData />;
    case 'loading':
      return <PageSkeleton />;
    case 'success':
      return (
        <Wrap>
          <FullWidthBlock gridArea="data">
            <FasterPayoutData data={data} />
          </FullWidthBlock>
          <FullWidthBlock gridArea="operations">
            <FasterPayoutOperationsTable
              operations={data.operations}
              currency={data.currencyCode}
            />
          </FullWidthBlock>
          <FullWidthBlock gridArea="history">
            <FasterPayoutHistory history={data.history} />
          </FullWidthBlock>
        </Wrap>
      );
  }
};

const Header = ({ id }: { id: number }) => {
  const { getCurrencyUnitRate, getCurrencyDecimalsCount } =
    useCurrencyCallbacks();
  const contractId = useURLContractId();
  const { status, data } = useFasterPayoutDataQuery(id);
  const location = useLocation();
  const { t } = useTranslation();
  const { getCurrencySymbol } = useCurrencyCallbacks();
  switch (status) {
    case 'loading':
      return null;
    case 'error':
      return null;
    case 'success': {
      const { amount, currencyCode } = data;
      const suffix = currencyCode
        ? `\u00a0${getCurrencySymbol(currencyCode)}`
        : '';
      return (
        <HStack
          alignItems="flex-end"
          justifyContent="space-between"
          mb={{ base: 2, sm: 5 }}
        >
          <Helmet title={t('titles:fasterPayout')} />
          <div>
            <Breadcrumb separator={<>&mdash;</>}>
              <BreadcrumbBackButton
                to={`/${contractId}/faster-payouts/${location.search}`}
              />
            </Breadcrumb>
            {currencyCode && amount && (
              <VStack alignItems="flexStart" spacing={0}>
                <PageTitle mb={0}>
                  <ReactNumberFormat
                    suffix={suffix}
                    value={amount / getCurrencyUnitRate(currencyCode)}
                    displayType="text"
                    isNumericString={true}
                    decimalSeparator={'.'}
                    decimalScale={getCurrencyDecimalsCount(currencyCode)}
                    allowLeadingZeros={true}
                    thousandSeparator={' '}
                    fixedDecimalScale={true}
                  />
                </PageTitle>
              </VStack>
            )}
          </div>
        </HStack>
      );
    }
  }
};

export default FasterPayoutPage;
