import React from 'react';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import RefundPaymentForm from '../forms/RefundPaymentForm/RefundPaymentForm';
import { PaylerModal } from '@payler/ui-components';
import { useTranslation } from 'react-i18next';

const refundPaymentModalOpenedAtom = atom<number | undefined>({
  key: createRecoilKey('refundPaymentModalOpenedAtom'),
  default: undefined,
});

export const useShowRefundPaymentModal = () =>
  useRecoilCallback(
    ({ set }) =>
      (id: number) => {
        set(refundPaymentModalOpenedAtom, id);
      },
    []
  );

export const useCloseRefundPaymentModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(refundPaymentModalOpenedAtom, undefined);
      },
    []
  );

export const RefundPaymentModal = () => {
  const { t } = useTranslation('payments');
  const id = useRecoilValue(refundPaymentModalOpenedAtom);
  const close = useCloseRefundPaymentModal();
  return (
    <PaylerModal close={close} open={!!id} title={t('refundTitle')}>
      {!!id && <RefundPaymentForm id={id} onSubmit={close} />}
    </PaylerModal>
  );
};
