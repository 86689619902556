import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { PAYMENT_FILTERS_PAYMENT_METHODS } from '../const';

export const usePaymentMethodsOptions = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    const fieldName = t('fields:paymentMethod');
    const options: { label: string; value: string }[] = [];

    options.push(
      ...PAYMENT_FILTERS_PAYMENT_METHODS.map((x) => ({
        value: x,
        label: t(`paymentMethod:${x}`),
      }))
    );

    return { fieldName, options };
  }, [t]);
};
