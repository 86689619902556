export const apmPaymentsEn = {
  previousPayments: 'Previous payments',
  title: 'Payments APM',
  status: {
    Created: 'Created',
    Pending: 'Pending',
    Completed: 'Completed',
    Failed: 'Failed',
    Returned: 'Returned',
    Succeed: 'Succeed',
  },
  dataTitle: 'Payment details',
  name: 'Name',
  phone: 'Phone',
  bankName: 'Bank',
  payoutId: 'Payout ID',
  history: 'Operations history',
  requisites: 'Requisites',
  tooltip: 'Faster payment',
  spbOrderId: 'ID платежа в НСПК',
  refund: 'Refund',
  downloadPDF: 'Download PDF',
  sessionId: 'Session ID',
  orderId: 'Order ID',
  direction: 'Direction',
  paymentMethod: 'Payment Method',
  statusDescription: 'Status',
  amount: 'Amount',
  currencyCode: 'Currency Code',
  date: 'Date',
};

type TApmPaymentsTranslation = typeof apmPaymentsEn;

export const apmPaymentsEs: TApmPaymentsTranslation = {
  previousPayments: 'Pagos anteriores',
  title: 'Pagos APM',
  status: {
    Completed: 'Con éxito',
    Failed: 'Failed',
    Returned: 'Devuelto',
    Created: 'Creado',
    Pending: 'En proceso',
    Succeed: 'Succeed',
  },
  name: 'Nombre',
  phone: 'Teléfono',
  history: 'Historial de operaciones',
  dataTitle: 'Detalles de pago',
  bankName: 'Bank',
  payoutId: 'ID de pago',
  requisites: 'Detalles bancarios',
  tooltip: 'Pago rápido',
  spbOrderId: 'ID платежа в НСПК',
  //todo: эта локализация будет удаляться
  refund: 'Reembolso',
  downloadPDF: 'Descargar en pdf',
  sessionId: 'Session ID',
  orderId: 'Order ID',
  direction: 'Direction',
  paymentMethod: 'Payment Method',
  statusDescription: 'Status',
  amount: 'Amount',
  currencyCode: 'Currency Code',
  date: 'Date',
};

export const apmPaymentsRu: TApmPaymentsTranslation = {
  previousPayments: 'Предыдущие платежи',
  title: 'Платежи APM',
  status: {
    Completed: 'Успешный',
    Failed: 'Неуспешный',
    Returned: 'Возвращен',
    Created: 'Создан',
    Pending: 'В обработке',
    Succeed: 'Успешный',
  },
  name: 'ФИО',
  dataTitle: 'Данные платежа',
  bankName: 'Банк',
  phone: 'Телефон',
  history: 'История операций',
  payoutId: 'ID выплаты',
  requisites: 'Реквизиты',
  tooltip: 'Платёж СБП',
  spbOrderId: 'ID платежа в НСПК',
  downloadPDF: 'Скачать PDF',
  refund: 'Вернуть',
  sessionId: 'ID Сессии',
  orderId: 'ID Заказа',
  direction: 'Направление',
  paymentMethod: 'Способ оплаты',
  statusDescription: 'Статус',
  amount: 'Сумма',
  currencyCode: 'Код валюты',
  date: 'Дата',
};
