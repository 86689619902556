export const reportStatusEn = {
  Created: 'Created',
  Error: 'Error',
  Processed: 'Done',
  Processing: 'Processing',
};

type TReportStatusTranslation = typeof reportStatusEn;

export const reportStatusEs: TReportStatusTranslation = {
  Created: 'En progreso',
  Error: 'Error',
  Processed: 'Listo',
  Processing: 'Pendiente',
};

export const reportStatusRu: TReportStatusTranslation = {
  Created: 'В ожидании',
  Error: 'Ошибка',
  Processed: 'Готов',
  Processing: 'В обработке',
};
