import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/ru';
import { Outlet } from 'react-router-dom';
import { Logo } from '../../components/Logo/Logo';
import { useTranslation } from 'react-i18next';
import React from 'react';
import useResizeObserver from 'use-resize-observer';
import { css } from '@emotion/react';
import { ErrorBoundary } from '@payler/ui-components';
import ErrorPage from '../../pages/ErrorPage';

const boxStyle = css`
  min-height: var(--window-inner-height);
`;

export const AuthLayout: React.FC = () => {
  const onResize = () => {
    let prevWidth = 0;
    return () => {
      if (prevWidth !== window.innerWidth) {
        prevWidth = window.innerWidth;
        document.documentElement.style.setProperty(
          '--window-inner-height',
          window.innerHeight + 'px'
        );
      }
    };
  };
  useResizeObserver({
    ref: document.body,
    onResize: onResize(),
  });
  return (
    <Flex css={boxStyle}>
      <Box
        display={{ sm: 'block', base: 'none' }}
        bg="menuBG"
        w="60%"
        pl={1}
        pr={1}
      >
        <ImageBlock />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        p={{ base: 2, sm: 10 }}
        bg="secondary.500"
      >
        <AuthHeader />
        <ErrorBoundary errorPage={<ErrorPage />}>
          <Flex minW={40} flex="1">
            <Outlet />
          </Flex>
        </ErrorBoundary>
      </Box>
    </Flex>
  );
};

const ImageBlock = () => {
  return (
    <Flex
      h="100%"
      justifyContent="center"
      direction="column"
      align="center"
      className="container"
    >
      <Image src="/assets/png/authImage.png" />
      <ImageTextBlock />
    </Flex>
  );
};

const AuthHeader = () => {
  return (
    <Flex p="0" justifyContent="space-between">
      <Logo m={{ base: 0, sm: 0 }} ml={0} />
    </Flex>
  );
};

const ImageTextBlock = () => {
  const { t } = useTranslation();
  return (
    <Flex mt={9} direction="column" textAlign="center">
      <Text textStyle={TextStyles.h1} color="primary.500">
        {t('auth:imageTitle')}
      </Text>
      <Text textStyle={TextStyles.BodyText16Regular} color="primary.400">
        {t('auth:imageSubtitle')}
      </Text>
    </Flex>
  );
};

export default AuthLayout;
