import { useTranslation } from 'react-i18next';
import { useCurrencyTranslation } from '../../hooks/currencies-info';
import React, { Suspense, useEffect } from 'react';

const CurrencyTranslationComp = () => {
  const { i18n } = useTranslation();
  const translation = useCurrencyTranslation();
  useEffect(() => {
    const { ru, es, en } = translation;
    i18n.addResources('ru', 'currency', ru);
    i18n.addResources('en', 'currency', en);
    i18n.addResources('es', 'currency', es);
  }, [i18n, translation]);
  return null;
};

/**
 * Добавляет перевод названий валют из словаря валют в i18n
 * @constructor
 */
export const CurrencyTranslation = () => (
  <Suspense fallback={null}>
    <CurrencyTranslationComp />
  </Suspense>
);
