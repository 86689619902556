export const notificationsEn = {
  deleteTitle: 'Delete note',
  deleteDescription: 'Are you sure you want to delete this note?',
  delete: 'Delete',
  createTitle: 'New notification',
  create: 'Add notification',
  created: 'Notification added successfully',
  createMobile: 'Add',
  allTriggers: 'All events',
  invalidEmail: 'Enter a valid e-mail',
  invalidUrl: 'Please enter valid data',
  addEmail: '+ Add email',
  update: 'Save',
  updated: 'Notification edited successfully',
  cancel: 'Cancel',
  deleted: 'Notification successfully deleted',
  title: 'Notifications',
  triggers: 'Events',
  recipients: 'Notification address',
  actions: {
    Post: 'POST notification to the server',
    Email: 'Email Notification',
  },
};

type TNotificationsTranslation = typeof notificationsEn;

export const notificationsEs: TNotificationsTranslation = {
  deleteTitle: 'Eliminar notes',
  deleteDescription: '¿Seguro que quieres eliminar este note?',
  delete: 'Eliminar',
  create: 'Agregar notificación',
  createTitle: 'Nueva notificación',
  createMobile: 'Agregar',
  created: 'Notificación agregada exitosamente',
  allTriggers: 'Todos los eventos',
  invalidEmail: 'Ingrese un correo electrónico válido',
  invalidUrl: 'Por favor, introduzca datos válidos',
  addEmail: '+ Añadir correo electrónico',
  cancel: 'Cancelar',
  update: 'Guardar',
  updated: 'Datos editado con éxito',
  deleted: 'Notificación eliminada exitosamente',
  title: 'Notificaciones',
  triggers: 'Eventos',
  recipients: 'Dirección de notificación',
  actions: {
    Post: 'Notificación POST al servidor',
    Email: 'Notificación por correo electrónico',
  },
};

export const notificationsRu: TNotificationsTranslation = {
  deleteTitle: 'Удаление уведомления',
  deleteDescription: 'Вы действительно хотите удалить это уведомление?',
  delete: 'Удалить',
  create: 'Добавить уведомление',
  createTitle: 'Новое уведомление',
  createMobile: 'Добавить',
  created: 'Уведомление успешно добавлено',
  invalidEmail: 'Введите корректный email',
  invalidUrl: 'Укажите корректные данные',
  allTriggers: 'Все события',
  addEmail: '+ Добавить email',
  cancel: 'Отмена',
  update: 'Сохранить',
  updated: 'Уведомление успешно отредактировано',
  deleted: 'Уведомление успешно удалено',
  title: 'Уведомления',
  triggers: 'События',
  recipients: 'Адрес для отправки уведомления',

  actions: {
    Post: 'POST уведомление на сервер',
    Email: 'E-mail уведомление',
  },
};
