import { useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { PaylerModal } from '@payler/ui-components';
import { FasterPaymentsFiltersForm } from '../forms/FasterPaymentsFilters/FasterPaymentsFiltersForm';

const fasterPaymentsModalOpenedAtom = atom({
  key: createRecoilKey('fasterPaymentsModalOpenedAtom'),
  default: false,
});

export const useShowFasterPaymentsModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(fasterPaymentsModalOpenedAtom, true);
      },
    []
  );

export const useCloseFasterPaymentsModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(fasterPaymentsModalOpenedAtom, false);
      },
    []
  );

export const FasterPaymentsFiltersModal = () => {
  const visible = useBreakpointValue({ base: true, lg: false });
  const open = useRecoilValue(fasterPaymentsModalOpenedAtom);
  const close = useCloseFasterPaymentsModal();
  if (!visible) return null;
  return (
    <PaylerModal close={close} open={open}>
      <FasterPaymentsFiltersForm />
    </PaylerModal>
  );
};
