export function getArrayOrUndefined<T extends string>(
  val: string[],
  checkArray?: T[]
): T[] | undefined {
  if (val.length === 0) return undefined;
  if (checkArray) {
    return checkArray.filter((x) => val.includes(x as string));
  }
  return val as T[];
}

export function getFloatOrUndefined(val: string | null): number | undefined {
  if (!val) return undefined;
  const tmp = parseFloat(val);
  return isNaN(tmp) ? undefined : tmp;
}
