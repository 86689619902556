import React from 'react';
import { NewReportForm } from '../forms/NewReportForm/NewReportForm';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { PaylerModal } from '@payler/ui-components';
import { useTranslation } from 'react-i18next';

const newReportModalOpenedAtom = atom({
  key: createRecoilKey('NewReportModal'),
  default: false,
});

export const useShowNewReportModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(newReportModalOpenedAtom, true);
      },
    []
  );

export const useCloseNewReportModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(newReportModalOpenedAtom, false);
      },
    []
  );

export const NewReportModal = () => {
  const { t } = useTranslation('reports');
  const open = useRecoilValue(newReportModalOpenedAtom);
  const close = useCloseNewReportModal();
  return (
    <PaylerModal close={close} open={open} title={t('newReport')}>
      <NewReportForm onSubmit={close} />
    </PaylerModal>
  );
};
