import {
  Container,
  ContainerProps,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { FC } from 'react';
import { AlertSmallIcon } from '../../../../../bank-utils/src/lib/icons';

export type TAlertStatus = 'error' | 'warning' | 'info';

export type TAlertProps = {
  status: TAlertStatus;
  label?: string;
  description?: string;
};

export const Alert: FC<TAlertProps & ContainerProps> = ({
  status,
  label,
  description,
  ...props
}) => {
  let iconColor;
  let bgColor;
  let borderColor;
  switch (status) {
    case 'warning':
      iconColor = 'yellow.500';
      bgColor = 'yellow.100';
      borderColor = 'yellow.100';
      break;
    case 'error':
      iconColor = 'red.500';
      bgColor = 'red.100';
      borderColor = 'red.100';
      break;
    case 'info':
    default:
      iconColor = 'yellow.500';
      bgColor = 'primary.25';
      borderColor = 'primary.50';
  }

  return (
    <Container
      bgColor={bgColor}
      borderRadius={1.5}
      border="1px solid"
      borderColor={borderColor}
      p={2}
      {...props}
    >
      <VStack spacing={1} alignItems="start">
        {label && (
          <HStack spacing={0.5}>
            <Icon as={AlertSmallIcon} color={iconColor} />
            <Text textStyle={TextStyles.Tagline10Bold}>{label}</Text>
          </HStack>
        )}
        {description && (
          <Text textStyle={TextStyles.Subtitle14Regular}>{description}</Text>
        )}
      </VStack>
    </Container>
  );
};
