import { QueryClient } from '@tanstack/react-query';
import createLogger from 'debug';

const log = createLogger('QueryClient');

export const queryClient = new QueryClient({
  logger: {
    log: (...args) => log('log', ...args),
    warn: (...args) => log('warn', ...args),
    error: (...args) => log('error', ...args),
  },
});
