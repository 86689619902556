import {
  Button,
  chakra,
  HStack,
  Icon,
  Menu,
  MenuButtonProps,
  MenuList,
  useMenuButton,
  useMenuState,
  VStack,
} from '@chakra-ui/react';
import React, { forwardRef, useState } from 'react';
import { TextStyles } from '@payler/ui-theme';
import { ChevronDownIcon } from '@payler/ui-icons';
import { useTranslation } from 'react-i18next';
import { ThinDivider } from '@payler/ui-components';
import { useContracts } from '../../../hooks/contracts';

import { CustomCheckbox } from './CustomCheckbox';
import { useAnalyticsPageContext } from '../../../context/AnalyticsPageContext';

const TriggerButton = forwardRef<HTMLDivElement, MenuButtonProps>(
  (props, ref) => {
    const { isOpen } = useMenuState();
    const { t } = useTranslation('analytics');
    const contracts = useContracts();
    const {
      params: { ContractIds: value },
    } = useAnalyticsPageContext();
    const buttonProps = useMenuButton(props, ref);
    if (!contracts) return null;
    let text;
    if (value.length === 1) {
      text = contracts.find((c) => c.id === value[0])?.name;
    } else if (value.length === contracts.length) {
      text = t('allContractsSelected');
    } else {
      text = t('common:selectedCountOf', {
        count: value.length,
        total: contracts.length,
      });
    }
    return (
      <HStack
        {...buttonProps}
        bg="primary.25"
        borderRadius={1.5}
        border="1px solid"
        borderColor="primary.100"
        px={2}
        py={1}
        w="250px"
        h="56px"
        userSelect="none"
        justifyContent="space-between"
      >
        <VStack alignItems="stretch" spacing={0} minW={0}>
          <Label>{t('contracts')}</Label>
          <Value>{text}</Value>
        </VStack>
        <Icon
          as={ChevronDownIcon}
          color="primary.350"
          w="20px"
          h="20px"
          transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
          transitionDuration="normal"
        />
      </HStack>
    );
  }
);

const Label = chakra('div', {
  baseStyle: {
    textStyle: TextStyles.Caption12Regular,
    color: 'primary.350',
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
});
const Value = chakra('div', {
  baseStyle: {
    textStyle: TextStyles.BodyUI16Regular,
    color: 'primary.500',
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const ContractsDropdown = () => {
  return (
    <Menu isLazy preventOverflow>
      <TriggerButton minW="250px" maxW="350px" />
      <MenuList
        py={0}
        pb={2}
        bg="secondary.500"
        borderRadius={1.5}
        border="1px solid"
        borderColor="primary.100"
      >
        <ContractsBody />
      </MenuList>
    </Menu>
  );
};

const ContractsBody = () => {
  const contracts = useContracts();
  const { onClose } = useMenuState();
  const { t } = useTranslation();
  const { params, setParams } = useAnalyticsPageContext();
  const [value, setValue] = useState(() => params.ContractIds);

  if (!contracts) return null;

  const toggle = (checked: boolean, checkBoxValue: number) => {
    setValue((prev) =>
      checked
        ? [...prev, checkBoxValue]
        : prev.filter((x) => x !== checkBoxValue)
    );
  };

  const submit = () => {
    setParams((v) => ({ ...v, ContractIds: value }));
    onClose();
  };

  const allSelected = value.length === contracts.length;

  const toggleAllSelected = () => {
    const newValue = allSelected ? [] : contracts.map((x) => x.id);
    setValue(newValue);
  };

  return (
    <>
      <VStack spacing={0} alignItems="stretch" divider={<ThinDivider />}>
        <CustomCheckbox
          selected={allSelected}
          text={t('selectAll')}
          onClick={toggleAllSelected}
        />
        {contracts.map((c) => {
          const selected = value.includes(c.id);
          return (
            <CustomCheckbox
              key={c.id}
              selected={selected}
              text={c.name ?? 'no name 😱'}
              onClick={() => toggle(!selected, c.id)}
            />
          );
        })}
      </VStack>
      <ThinDivider mb={2} />

      <HStack justifyContent="stretch" px={2} spacing={2}>
        <Button variant="secondary" onClick={onClose} size="small" flex={1}>
          {t('cancel')}
        </Button>
        <Button
          onClick={submit}
          flex={1}
          isDisabled={value.length === 0}
          size="small"
        >
          {t('apply')}
        </Button>
      </HStack>
    </>
  );
};
