import { ApiMerchantCabinet } from '@payler/api/merchant-cabinet';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import createLogger from 'debug';
import { useApi } from './ApiContextProvider';
import { jwtDecode } from 'jwt-decode';

const log = createLogger('AuthContextProvider');

type TAuthContext = {
  isAuthorized: boolean;
};

const AuthContext = createContext<TAuthContext>({ isAuthorized: false });
const TOKEN_KEY = 'token';
const DEBUG_REFRESH_TIMEOUT_KEY = 'debug-refresh-timeout';

/**
 * Получить таймута по истечении которого следует обновить токен
 * @param token
 */
function getTokenRefreshTimeout(token: string) {
  const { exp } = jwtDecode<{ exp?: number }>(token);
  if (!exp) {
    throw new Error(`can't get refresh timeout from token`);
  }
  const debugTimeout = Number(localStorage.getItem(DEBUG_REFRESH_TIMEOUT_KEY));
  if (!isNaN(debugTimeout) && debugTimeout > 0) {
    log('getTokenRefreshTimeout DEBUG: %i', debugTimeout);
    return debugTimeout;
  }
  // за минуту до протухания
  const timeout = (exp - 60) * 1000 - Date.now();
  log('getTokenRefreshTimeout: %i', timeout);
  return timeout;
}

/**
 * эмулировать событие storage
 * @param token
 */
function dispatchTokenEvent(token: string | null) {
  log('dispatching storage event %O', { key: TOKEN_KEY, newValue: token });
  window.dispatchEvent(
    new StorageEvent('storage', { key: TOKEN_KEY, newValue: token })
  );
}

/**
 * Установить токен в localStorage и эмулировать событие storage
 * Событие слушается в AuthContextProvider
 * @param token
 */
export function setTokenAndDispatchEvent(token: string | null) {
  log('setToken, token %O', { token });
  if (!token) {
    localStorage.removeItem(TOKEN_KEY);
    dispatchTokenEvent(null);
  } else {
    const expired = checkTokenExpired(token);
    if (!expired) {
      localStorage.setItem(TOKEN_KEY, token);
      dispatchTokenEvent(token);
    } else {
      localStorage.removeItem(TOKEN_KEY);
      dispatchTokenEvent(null);
    }
  }
}

/**
 * Обновить токен
 */
async function refreshToken(api: ApiMerchantCabinet) {
  try {
    const prevToken1 = localStorage.getItem(TOKEN_KEY);
    const { token, message } = await api.refreshToken();
    log('refresh token response: (token: %s, message: %s)', token, message);
    const prevToken2 = localStorage.getItem(TOKEN_KEY);
    if (prevToken1 !== prevToken2) {
      // возможно другой tab уже обновил токен
      log('token already updated');
      return;
    }
    if (token) {
      setTokenAndDispatchEvent(token);
    } else {
      log('unable to refresh token, message: %s', message);
      setTokenAndDispatchEvent(null);
    }
  } catch (e) {
    log('error in refresh token: %O', e);
  }
}

let timeoutHandle: ReturnType<typeof setTimeout>;

/**
 * Авторизован пользователь или нет, так же делает рефреш токена
 * @param children
 * @constructor
 */
export const AuthContextProvider: FCC = ({ children }) => {
  const api = useApi();
  const [authorized, setAuthorized] = useState<boolean>(() => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const expired = checkTokenExpired(token);
      if (expired) {
        localStorage.removeItem(TOKEN_KEY);
      } else {
        timeoutHandle = setTimeout(
          () => refreshToken(api),
          getTokenRefreshTimeout(token)
        );
      }
      return !expired;
    }
    return false;
  });

  //localstorage listener
  useEffect(() => {
    const listener = (e: StorageEvent) => {
      log('storage listener, token from storage event: %O', e);
      if (e.key === TOKEN_KEY) {
        const token = e.newValue;
        const expired = checkTokenExpired(token);
        setAuthorized(!expired);
        if (token && !expired) {
          clearTimeout(timeoutHandle);
          const timeoutValue = getTokenRefreshTimeout(token);
          if (timeoutValue >= 10_000) {
            log(`token refresh timeout set to ${timeoutValue} ms`);
            timeoutHandle = setTimeout(() => refreshToken(api), timeoutValue);
          }
        }
      }
    };

    window.addEventListener('storage', listener);
    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [api]);

  const ctx = useMemo<TAuthContext>(() => {
    return { isAuthorized: authorized };
  }, [authorized]);

  return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>;
};

function checkTokenExpired(token: string | null): boolean {
  if (!token) return true;
  let expired = true;
  try {
    const { exp } = jwtDecode<{ exp?: number }>(token);
    expired = !exp || exp * 1000 < Date.now();
  } catch (e) {
    log('checkToken error %O', e);
  }
  return expired;
}

/**
 * Авторизован ли пользователь
 */
export const useIsAuthorized = () => useContext(AuthContext).isAuthorized;
