import { TPaymentIndicators } from '@payler/api/merchant-cabinet';

export const paymentIndicatorEn: Record<TPaymentIndicators | string, string> = {
  field: 'Payment type',
  IsAntifraudDeclined: 'Antifraud',
  IsApplePay: 'Apple pay',
  IsBillPay: 'Link Payment',
  IsGooglePay: 'Google pay',
  IsPartRefunded: 'Partial refund',
  IsRecurrentPay: 'Recurring payment',
  IsYandexPay: 'Yandex pay',
};
type TPaymentIndicatorTranslation = typeof paymentIndicatorEn;

export const paymentIndicatorEs: TPaymentIndicatorTranslation = {
  field: 'Tipo de pago',
  IsAntifraudDeclined: 'Antifraud',
  IsApplePay: 'Apple pay',
  IsBillPay: 'Enlace exhibido',
  IsGooglePay: 'Google pay',
  IsPartRefunded: 'Reembolso parcial',
  IsRecurrentPay: 'Pago recurrente',
  IsYandexPay: 'Yandex pay',
};

export const paymentIndicatorRu: TPaymentIndicatorTranslation = {
  field: 'Признак платежа',
  IsAntifraudDeclined: 'Антифрод',
  IsApplePay: 'Apple pay',
  IsBillPay: 'Выставлен по ссылке',
  IsGooglePay: 'Google pay',
  IsPartRefunded: 'Частичный возврат',
  IsRecurrentPay: 'Рекуррентный платёж',
  IsYandexPay: 'Yandex pay',
};
