import React, { Suspense, useState } from 'react';
import { Card, CardContent, CardHead, CardTitle } from '@payler/ui-components';
import { PaymentSettingsForm } from '../../forms/PaymentSettingsForm/PaymentSettingsForm';
import {
  HStack,
  Icon,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { PencilIcon } from '@payler/ui-icons';
import { useContracts } from '../../hooks/contracts';
import { TExtendedContract } from '@payler/api/merchant-cabinet';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { useMerchantPaymentTypeOptions } from '../../hooks/useMerchantPaymentTypeOptions';
import { merchantTerminalLanguageOptions } from '../../helpers/consts';
import { FullWidthSettingsBlock } from '../../layouts';
import { useUserPermissions } from '../../hooks/user/users';

const PaymentSettingsListComp = () => {
  const contracts = useContracts();
  const contractList = contracts?.map((c) => (
    <EditFormCard key={c.id} contract={c} />
  ));
  const desktop = useBreakpointValue({ base: false, md: true });
  return desktop ? (
    <VStack spacing={1} w={{ base: 'calc(100vw - 36px)', md: '100%' }}>
      {contractList}
    </VStack>
  ) : (
    <FullWidthSettingsBlock gridArea="form">
      <VStack spacing={1}>{contractList}</VStack>
    </FullWidthSettingsBlock>
  );
};

const ChangeIcon: React.FC<{
  color: string;
  onEdit: () => void;
}> = ({ onEdit, color }) => (
  <Icon
    as={PencilIcon}
    h="24px"
    w="24px"
    cursor="pointer"
    color={color}
    onClick={onEdit}
  />
);

const EditFormCard: React.FC<{ contract: TExtendedContract }> = ({
  contract,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const { canChangePaymentSettings } = useUserPermissions();
  return (
    <Card w="100%" maxW="1200px">
      <CardHead>
        <CardTitle>{contract.name ?? ''}</CardTitle>
        {canChangePaymentSettings && (
          <ChangeIcon
            color={isEdit ? 'brands.500' : 'primary.300'}
            onEdit={() => setIsEdit(true)}
          />
        )}
      </CardHead>
      <CardContent
        px={{
          base: 2,
          md: 3,
        }}
        py={{
          base: 2,
          md: 3,
        }}
      >
        {isEdit ? (
          <PaymentSettingsForm
            contract={contract}
            onCancel={() => setIsEdit(false)}
          />
        ) : (
          <DataCard contract={contract} />
        )}
      </CardContent>
    </Card>
  );
};

const DataCard: React.FC<{ contract: TExtendedContract }> = ({ contract }) => {
  const { t } = useTranslation('settings', { keyPrefix: 'payment.form' });
  const paymentTypeOptions = useMerchantPaymentTypeOptions();
  return (
    <VStack w="100%" spacing={2} alignItems="baseline">
      <HStack w="100%" spacing={5}>
        <div>
          <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
            {t('paymentType')}
          </Text>
          <Text textStyle={TextStyles.tables} color="primary.500">
            {paymentTypeOptions.find((pO) => pO.value === contract.paymentType)
              ?.label ?? ''}
          </Text>
        </div>
        <div>
          <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
            {t('lang')}
          </Text>
          <Text textStyle={TextStyles.tables} color="primary.500">
            {merchantTerminalLanguageOptions.find(
              (l) => l.value === contract.lang
            )?.label ?? ''}
          </Text>
        </div>
      </HStack>
      {contract.allowedIps?.length === 0 ? null : (
        <div>
          <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
            {t('ips')}
          </Text>
          <Text textStyle={TextStyles.tables} color="primary.500">
            {contract.allowedIps?.join(',')}
          </Text>
        </div>
      )}
      <div>
        <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
          {t('url')}
        </Text>
        <Text textStyle={TextStyles.tables} color="primary.500">
          {contract.extCustomerUrl}
        </Text>
      </div>
    </VStack>
  );
};

export const PaymentSettingsList: React.FC = () => (
  <Suspense fallback={null}>
    <PaymentSettingsListComp />
  </Suspense>
);
