import { useTranslation } from 'react-i18next';
import { HStack, Text, Tooltip, useClipboard, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import React, { MouseEventHandler, useCallback } from 'react';
import { MayBe } from '@payler/api/merchant-cabinet';

export const IdsCell = ({
  orderId,
  id,
}: {
  id: MayBe<number>;
  orderId: MayBe<string>;
}) => {
  const { t } = useTranslation();
  const { hasCopied, onCopy } = useClipboard(orderId ?? '');
  const handleCopy: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onCopy();
    },
    [onCopy]
  );
  return (
    <VStack alignItems="flex-end" spacing={0}>
      <HStack>
        <Text textStyle={TextStyles.tables}>{id}</Text>
      </HStack>
      <Tooltip
        isOpen={hasCopied || undefined}
        label={hasCopied ? t('copied') : t('clickToCopy')}
        hasArrow
        placement="top"
      >
        <Text
          cursor="pointer"
          textStyle={TextStyles.Caption12Regular}
          color="primary.350"
          maxW={{ base: '140px', lg: '350px' }}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          onClick={handleCopy}
        >
          ID:&nbsp;{orderId}
        </Text>
      </Tooltip>
    </VStack>
  );
};
