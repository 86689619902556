export const withdrawalsEn = {
  form: {
    amount: 'Amount, {{currency}}',
    cardNumber: 'Card number',
    invalidAmount: 'Enter amount',
    invalidCard: 'Enter card number',
    invalidEmail: 'Enter a valid e-mail',
    invalidPassword: 'Enter payment password',
    password: 'Payment password',
    remember: 'Remember form data',
    send: 'Send',
    success: 'Payment successful.',
    templates: 'Templates',
    noAccess: 'Not enough rights',
    expirationDate: 'Expiration date',
  },
  formTitle: 'Withdrawal to bank card',
  listTitle: 'Previous payments (1 day)',
  title: 'Withdrawals',
};

type TWithdrawalsTranslation = typeof withdrawalsEn;

export const withdrawalsEs: TWithdrawalsTranslation = {
  form: {
    templates: 'Plantillas',
    invalidEmail: 'Ingrese un correo electrónico válido',
    invalidAmount: 'Ingrese el monto',
    invalidCard: 'Ingrese el número de tarjeta',
    invalidPassword: 'Ingrese la contraseña de facturación',
    amount: 'La cantidad, {{currency}}',
    remember: 'Recordar datos del formulario',
    send: 'Enviar',
    cardNumber: 'Número de tarjeta',
    password: 'Contraseña de facturación',
    success: 'Pago exitoso.',
    noAccess: 'No hay suficientes derechos',
    expirationDate: 'Fecha de caducidad',
  },
  formTitle: 'Retiro a la tarjeta bancaria',
  listTitle: 'Pagos previos (1 día)',
  title: 'Hacer un pago',
};

export const withdrawalsRu: TWithdrawalsTranslation = {
  form: {
    amount: 'Сумма, {{currency}}',
    cardNumber: 'Номер карты',
    invalidAmount: 'Введите сумму',
    invalidCard: 'Укажите номер карты',
    invalidEmail: 'Введите корректный email',
    invalidPassword: 'Укажите платёжный пароль',
    password: 'Платёжный пароль',
    remember: 'Запомнить данные формы',
    send: 'Отправить',
    success: 'Выплата проведена успешно.',
    templates: 'Шаблоны',
    noAccess: 'Недостаточно прав',
    expirationDate: 'Срок действия',
  },
  formTitle: 'Вывод на карту',
  listTitle: 'Предыдущие платежи (1 день)',
  title: 'Сделать выплату',
};
