export const fasterPayoutsEn = {
  previousPayments: 'Previous payouts',
  title: 'Payouts FPS',
  status: {
    Created: 'Created',
    Ready: 'Ready',
    Success: 'Success',
    Rejected: 'Rejected',
    Pending: 'Pending',
  },
  dataTitle: 'Payment details',
  name: 'Name',
  phone: 'Phone',
  bankName: 'Bank',
  orderId: 'Payout ID',
  processingName: 'Processing',
  history: 'Operations history',
  requisites: 'Requisites',
  tooltip: 'Faster payout',
};

type TFasterPayoutsTranslation = typeof fasterPayoutsEn;

export const fasterPayoutsEs: TFasterPayoutsTranslation = {
  previousPayments: 'Pagos anteriores',
  title: 'Transferencias FPS',
  status: {
    Created: 'Creado',
    Success: 'Con éxito',
    Ready: 'Listo para pagar',
    Rejected: 'Desechado',
    Pending: 'En proceso',
  },
  name: 'Nombre',
  phone: 'Teléfono',
  history: 'Historial de operaciones',
  dataTitle: 'Detalles de pago',
  bankName: 'Bank',
  orderId: 'ID de pago',
  processingName: 'Procesamiento',
  requisites: 'Detalles bancarios',
  tooltip: 'Pago rápido',
};

export const fasterPayoutsRu: TFasterPayoutsTranslation = {
  previousPayments: 'Предыдущие выплаты',
  title: 'Выплаты СБП',
  status: {
    Created: 'Создано',
    Ready: 'Готово к выплате',
    Success: 'Успешно',
    Rejected: 'Отклонено',
    Pending: 'В обработке',
  },
  name: 'ФИО',
  dataTitle: 'Данные платежа',
  bankName: 'Банк',
  phone: 'Телефон',
  history: 'История операций',
  orderId: 'ID выплаты',
  processingName: 'Процессинг',
  requisites: 'Реквизиты',
  tooltip: 'Выплата СБП',
};
