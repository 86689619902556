import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { Suspense } from 'react';
import {
  ErrorBoundary,
  NoData,
  ScrollBarWithFade,
} from '@payler/ui-components';
import TableSkeleton from '../../components/TableSkeleton/TableSkeleton';
import { TInvoice } from '@payler/api/merchant-cabinet';
import { useTranslation } from 'react-i18next';
import { InvoiceButtons } from './InvoiceButtons';
import { InvoiceState } from './InvoiceState';
import { CurrencyDisplay } from '../../components/CurrencyDisplay/CurrencyDisplay';
import { TableFooter } from '../components/TableFooter';
import { useDayjsFormat } from '@payler/utils';
import { LabeledValue } from '../../components/LabeledValue/LabeledValue';
import { tableStyleConfig } from '../tableStyleConfig';
import {
  useInvoicesQuery,
  useInvoicesQueryPagerProps,
} from '../../hooks/invoices/queries';
import { useLocation, useNavigate } from 'react-router-dom';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';

export const InvoicesTable = () => {
  const props = useInvoicesQueryPagerProps();
  return (
    <>
      <TableWithData />
      <ErrorBoundary>
        <React.Suspense fallback={null}>
          <TableFooter paginationProps={props} />
        </React.Suspense>
      </ErrorBoundary>
    </>
  );
};

export const TableWithData = () => {
  const { status, data } = useInvoicesQuery();
  switch (status) {
    case 'loading':
      return <TableSkeleton />;
    case 'error':
      return <NoData />;
    case 'success':
    default: {
      const empty = data.items.length === 0;
      if (empty) {
        return <NoData />;
      }
      return (
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Header />
              <Tbody>
                {data.items.map((invoice) => (
                  <Row key={invoice.id} data={invoice} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      );
    }
  }
};

const Row = ({ data }: { data: TInvoice }) => {
  const contractId = useURLContractId();
  const formatDate = useDayjsFormat();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Tr
      onClick={() =>
        navigate(`/${contractId}/bill/${data.sessionId}${location.search}`)
      }
    >
      <Td>
        <InvoiceState data={data} />
      </Td>
      <Td>
        <LabeledValue label={formatDate(data.created, 'D MMM YYYY')}>
          <CurrencyDisplay value={data.amount} currency={data.currencyCode} />
        </LabeledValue>
      </Td>
      <Td>
        <LabeledValue label={t('invoices:email')}>{data.email}</LabeledValue>
      </Td>
      <Td
        textAlign="right"
        onClick={(e) => e.stopPropagation()}
        cursor="default"
      >
        <Suspense fallback={null}>
          <InvoiceButtons data={data} />
        </Suspense>
      </Td>
    </Tr>
  );
};

const Header = () => {
  const { t } = useTranslation('tableHeader');
  return (
    <Thead>
      <Tr>
        <Th>{t('status')}</Th>
        <Th>{t('createdAndAmount')}</Th>
        <Th>{t('email')}</Th>
      </Tr>
    </Thead>
  );
};

export default InvoicesTable;
